<mat-card fxLayout="row-wrap" fxLayout.lt-sm="column" fxLayoutGap="2rem" style="margin-top:1rem; padding:1rem">
  <div fxLayout="column" fxLayoutalign="start center" style="margin:0rem">

    <div fxLayout="row-wrap" fxLayout.lt-sm="column" fxLayoutGap="2rem" fxLayoutGap.lt-sm="0rem">
      <h3 style="margin-left:2rem; font-family:Roboto; font-weight:500">Hantera träning: </h3>

      <mat-form-field appearance="fill">
        <mat-label>Välj klubb:</mat-label>
        <mat-select [(value)]="selectedClub"
                    [compareWith]="comparer"
                    (selectionChange)="onClubChange($event)">

          <mat-option *ngFor="let club of clubs" [value]="club">{{club.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <section>
        <mat-checkbox  [(ngModel)]="checked" (click)="onAllEvents()">Visa alla event</mat-checkbox>
      </section>
        
  
    </div>

     
    <p-table #dt [value]="events()" dataKey="eventId"
             [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
             currentPageReportTemplate="Totalt {totalRecords} event i databasen" [rowsPerPageOptions]="[10,25,50]"
             sortMode="multiple"
             [scrollable]="true" scrollHeight="500px"
             [responsive]="true"
             [filterDelay]="0"
             editMode="row">

      <ng-template pTemplate="caption">
        <div fxLayout="row wrap" fxLayoutGap="1rem">
          <div fxLayoutAlign="flex-start" fxFlex.gt-xs="40" fxFlex.lt-sm="25">
            <mat-lable class="name-header-sm">
              Events
            </mat-lable>
          </div>

          <!--<div fxLayoutAlign="end" fxFlex fxLayoutGap="1rem">
        <button class="mw-addBtn-icon" (click)="onAddEvent()"><mat-icon class="mw-addIcon-icon">add</mat-icon></button>
      </div>-->
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr fxFlexLayout="row" fxHide.lt-sm style="height:3rem">
          <th fxHide.lt-sm style="width: 4rem"></th>
          <th fxFlex="15%" pSortableColumn="eventDate">Datum <p-sortIcon field="eventDate"></p-sortIcon></th>
          <th fxFlex="15%" pSortableColumn="eventName"> Namn <p-sortIcon field="eventName"></p-sortIcon></th>
          <th fxFlex="15%"> Klubb </th>
          <th fxFlex="15%" > Event </th>
          <th fxFlex="10%" fxLayoutAlign="center center" pSortableColumn="hasStarted"> Status <p-sortIcon field="hasStarted"></p-sortIcon></th>
          <th fxFlex="20%" fxLayoutAlign="center center"> Actions</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-events let-ri="rowIndex" let-expanded="expanded">
        <!--LARGE SCREEN-->
        <tr fxFlexLayout="row" fxHide.lt-sm style="height:3rem">
          <td fxLayout fxLayoutAlign="start center">

            <button type="button" style="margin:0px" pButton pRipple [pRowToggler]="events"
                    class="p-button-text p-button-rounded p-button-plain"
                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                    (click)="onExpandRow(events)">       
            </button>

          </td>
          <td fxFlex="15%" fxLayout fxLayoutAlign="start center">
            {{events.eventDate}}
          </td>
          <td fxFlex="15%" fxLayout fxLayoutAlign="start center">
            {{events.eventName}}
          </td>
          <td fxFlex="15%" fxLayout fxLayoutAlign="start center">
            {{dataRoom.showClubName(events.clubModelId)}}
            <!--{{displayClubName(events)}}-->
          </td>
          <td fxFlex="15%" fxLayout fxLayoutAlign="start center">
            {{events.eventType.type}}
          </td>
          <td fxFlex="10%" fxLayout fxLayoutAlign="center center">
            <span><mat-icon [ngStyle]="{'color':getTheColor(events.hasStarted)}">circle</mat-icon></span>
          </td>
          <td fxFlex="20%" fxLayout fxLayoutAlign="end center">
            <div fxFlex fxLayoutAlign="end center">
              <button class="mw-addBtn-icon" (click)="onStartEvent(events)"><mat-icon>play_arrow</mat-icon></button>
              <button class="mw-pauseBtn-icon" (click)="onPauseEvent(events)"><mat-icon>pause</mat-icon></button>
              <button class="mw-deleteBtn-icon" (click)="onStopEvent(events)"><mat-icon>stop</mat-icon></button>
            </div>
          </td>
        </tr>
        <!--SMALL SCREEN-->
        <tr fxHide.gt-xs fxFlexLayout="column" style="height:3rem">
          <td style="background-color:gainsboro; padding:0.5rem" fxLayoutAlign="start center">
            <span fxFlex="20" class="p-column-title"> Namn: </span>
            <span fxFlex="35">{{events.eventName}}</span>
            <span fxFlex="10" style="margin-left:1rem"><mat-icon [ngStyle]="{'color':getTheColor(events.hasStarted)}">circle</mat-icon></span>
            <div fxFlex fxLayoutAlign="end center">
              <button type="button" style="margin:0px" pButton pRipple [pRowToggler]="events"
                      class="p-button-text p-button-rounded p-button-plain"
                      [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                      (click)="onExpandRow(events)">
              </button>
            </div>
          </td>
          <td fxLayoutAlign="start center">
            <span class="p-column-title"> Datum: </span>
            {{events.eventDate}}
          </td>
          <td fxLayoutAlign="start center">
            <span class="p-column-title"> Klubb: </span>
            {{dataRoom.showClubName(events.clubModelId)}}
            <!--{{displayClubName(events)}}-->
          </td>
          <td fxLayoutAlign="center center" fxLayoutGap="2rem">
            <button class="mw-addBtn-icon" (click)="onStartEvent(events)"><mat-icon>play_arrow</mat-icon></button>
            <button class="mw-pauseBtn-icon" (click)="onPauseEvent(events)"><mat-icon>pause</mat-icon></button>
            <button class="mw-deleteBtn-icon" (click)="onStopEvent(events)"><mat-icon>stop</mat-icon></button>
          </td>

        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-event>
        <div fxLayout="row-wrap" fxLayoutAlign="center start">
          <mat-card class="MatCard-mw" fxFlex="70" fxFlex.lt-lg="90" fxFlex.lt-sm="100">
            <mat-card-title fxFlexLayout="row-wrap" fxLayoutAlign="space-between center">
              <h6 style="margin:0px; padding:0px">Varv:</h6>    
            </mat-card-title>

            <mat-card-content fxLayout="row-wrap" fxLayout.xs="column" fxLayoutAlign="space-between start" fxLayoutGap="1rem">
              <p-table *ngFor="let lap of event.lapinEvent"
                       [value]=lap.lapModel.lapSetUps
                       [responsive]="true"
                       >
                <ng-template pTemplate="caption">
                  {{lap.lapModel.lapName}}
                </ng-template>
                <ng-template pTemplate="header">
                  <th  fxFlex="25%"  fxLayoutAlign="start center">Läsare </th>
                  <th  fxFlex="25%"  fxLayoutAlign="center center">Antenn </th>
                  <th fxFlex="25%"  fxLayoutAlign="start center">Position</th>
                  <th fxFlex fxLayoutAlign="center center"> Status </th>
                </ng-template>

                <ng-template pTemplate="body" let-lap let-ri="rowIndex">
                  <tr fxFlexLayout="row"  style="height:3rem">
                    <td fxFlex="25%"  fxLayoutAlign="start center">
                      {{lap.readerId}}
                    </td>
                    <td fxFlex="25%"  fxLayoutAlign="center center">
                      {{lap.antennaId}}
                    </td>
                    <td fxFlex="25%"  fxLayoutAlign="start center">
                      {{lap.name}}
                    </td>
                    <td fxFlex fxLayoutAlign="center center">
                      <mat-icon [ngStyle]="{'color':getTheColor2(lap.readerId)}">circle</mat-icon>
                    </td>
                  </tr>
                </ng-template>
              </p-table>


            </mat-card-content>
          </mat-card>
        </div>

      </ng-template>
    </p-table>
  </div>
</mat-card>
