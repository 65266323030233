<p-table #dt [value]="clubs" dataKey="clubModelId"
         [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
         currentPageReportTemplate="Totalt {totalRecords} medlemmar" [rowsPerPageOptions]="[10,25,50]"
         sortMode="multiple"
         [scrollable]="true" scrollHeight="500px"
         [responsive]="true"
         [filterDelay]="0" [globalFilterFields]="['svemoId','firstName','lastName']"
         editMode="row"    
         >
  <!--CAPTION-->
  <ng-template pTemplate="caption">
    <div fxLayout="row wrap" fxLayoutGap="1rem" >
      <div fxLayoutAlign="flex-start" fxFlex.gt-xs="40" fxFlex.lt-sm="25">
        <mat-lable class="name-header-sm">
          Klubbar
        </mat-lable>
      </div>

      <div fxLayoutAlign="end" fxFlex fxLayoutGap="1rem">
        <button class="mw-addBtn-icon" (click)="onAddClub()" fxLayoutAlign="start start"><mat-icon class="mw-addIcon-icon">add</mat-icon></button>
      </div>
    </div>
  </ng-template>

  <!--HEADER-->
  <ng-template pTemplate="header">
    <tr fxFlexLayout="row">
      <th fxHide.lt-sm style="width: 4rem"></th>
      <th fxFlex="25%" pSortableColumn="name">Klubb <p-sortIcon field="name"></p-sortIcon></th>
      <th fxFlex="25%" pSortableColumn="address"> Adress <p-sortIcon field="address"></p-sortIcon></th>
      <th fxFlex="30%" pSortableColumn="pictureUrl"> Logga <p-sortIcon field="pictureUrl"></p-sortIcon></th>
      <th fxFlex> </th>
    </tr>
  </ng-template>


  <ng-template pTemplate="body" let-club let-editing="editing" let-ri="rowIndex" let-expanded="expanded">
    <!--LARGE SCREEN-->

    <tr fxFlexLayout="row" fxHide.lt-sm style="height:3rem" [pEditableRow]="club">
      <td fxLayout fxLayoutAlign="start center">
        <!--<button type="button" style="width: 2rem; margin:0px" [pRowToggler]="club" [icon]="'pi pi-chevron-right'">E</button>-->
        <button type="button" style="margin:0px"  pButton pRipple [pRowToggler]="club" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
        <!--<button type="button" pButton pRipple style="width: 2rem; margin:0px" [pRowToggler]="club"><mat-icon>keyboard_arrow_right</mat-icon></button>-->
      </td>
      <td  fxFlex="25%" fxLayout fxLayoutAlign="start center">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <mat-form-field>
              <input matInput type="text" [(ngModel)]="club.name">
            </mat-form-field>
          </ng-template>
          <ng-template pTemplate="output">
            {{club.name}}
          </ng-template>
        </p-cellEditor>

      </td>
      <td  fxFlex="25%" fxLayout fxLayoutAlign="start center">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <mat-form-field>
              <input matInput type="text" [(ngModel)]="club.address">
            </mat-form-field>
          </ng-template>
          <ng-template pTemplate="output">
            {{club.address}}
          </ng-template>
        </p-cellEditor>
      </td>
      <td  fxFlex="30%" fxLayout fxLayoutAlign="start center">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <mat-form-field>
              <ngx-mat-file-input [multiple]="multiple" [accept]="accept" placeholder="Välj en fil" [(ngModel)]="club.picture">
                <mat-icon style="font-size:25px" ngxMatFileInputIcon>folder_open</mat-icon>
              </ngx-mat-file-input>
            </mat-form-field>
          </ng-template>
          <ng-template pTemplate="output">
            <img class="img" width="40" height="40" [src]='showImage(club.picture)' style="border-radius:10%;" />

          </ng-template>
        </p-cellEditor>
      </td>

      <td fxFlex fxLayoutAlign="start center">
        <div fxFlex fxLayoutAlign="end center">
          <button *ngIf="!editing" class="mw-editBtn-icon" pInitEditableRow (click)="onRowEditInit($event)">
            <mat-icon>edit</mat-icon>
          </button>
          <button *ngIf="!editing" class="mw-deleteBtn-icon" (click)="onDeleteClub(club)">
            <mat-icon class="mw-deleteIcon-icon">close</mat-icon>
          </button>
          <button *ngIf="editing" class="mw-okBtn-icon" pInitEditableRow pSaveEditableRow (click)="onRowEditSave(club)">
            <mat-icon class="mw-okIcon-icon">done</mat-icon>
          </button>
          <button *ngIf="editing" class="mw-deleteBtn-icon" pCancelEditableRow (click)="onRowEditCancel(club, ri)">
            <mat-icon class="mw-deleteIcon-icon">clear</mat-icon>
          </button>
        </div>
      </td>
    </tr>
    <!--SMALL SCREEN-->
    <tr fxHide.gt-xs [pEditableRow]="club">

      <td class="mw-smallTableheader" fxLayoutAlign="start center" fxLayout>
        <button fxFlex="2rem" type="button" style="margin-right:1rem; height:2rem" pButton pRipple [pRowToggler]="club" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
        <span fxFlex="20%" class="p-column-title"> Klubb: </span>
        <p-cellEditor fxFlex="20%">
          <ng-template pTemplate="input">
            <mat-form-field>
              <input matInput type="text" [(ngModel)]="club.name">
            </mat-form-field>
          </ng-template>
          <ng-template pTemplate="output">
            {{club.name}}
          </ng-template>
        </p-cellEditor>
        <div fxFlex fxLayoutAlign="end center">
          <button *ngIf="!editing" class="mw-editBtn-icon" pInitEditableRow (click)="onRowEditInit($event)">
            <mat-icon>edit</mat-icon>
          </button>
          <button *ngIf="!editing" class="mw-deleteBtn-icon" (click)="onDeleteClub(club)">
            <mat-icon class="mw-deleteIcon-icon">close</mat-icon>
          </button>
        </div>

      </td>

      <td fxLayoutAlign="start center">
        <span fxFlex="3rem"></span>
        <span class="p-column-title"> Adress: </span>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <mat-form-field>
              <input matInput type="text" [(ngModel)]="club.address">
            </mat-form-field>
          </ng-template>
          <ng-template pTemplate="output">
            {{club.address}}
          </ng-template>
        </p-cellEditor>
      </td>
      <td fxLayoutAlign="start center">
        <span fxFlex="3rem"></span>
        <span class="p-column-title"> Bild länk: </span>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <mat-form-field>
              <ngx-mat-file-input [multiple]="multiple" [accept]="accept" placeholder="Välj en fil" [(ngModel)]="club.picture">
                <mat-icon style="font-size:25px" ngxMatFileInputIcon>folder_open</mat-icon>
              </ngx-mat-file-input>
            </mat-form-field>
          </ng-template>
          <ng-template pTemplate="output">
            <img class="img" width="40" height="40" [src]='showImage(club.picture)' style="border-radius:10%;" />
          </ng-template>
        </p-cellEditor>

      </td>


      <td fxLayoutAlign="center center">
        <button *ngIf="editing" class="mw-okBtn-icon" pInitEditableRow pSaveEditableRow (click)="onRowEditSave(club)">
          <mat-icon class="mw-okIcon-icon">done</mat-icon>
        </button>
        <button *ngIf="editing" class="mw-deleteBtn-icon" pCancelEditableRow (click)="onRowEditCancel(club, ri)">
          <mat-icon class="mw-deleteIcon-icon">clear</mat-icon>
        </button>
        <!--<button *ngIf="editing" class="mw-btn" mat-raised-button pSaveEditableRow (click)="onRowEditSave(club)">
    <mat-icon class="mw-accept-icon">done</mat-icon>
  </button>-->
        <!--<button *ngIf="editing" class="mw-btn" mat-raised-button pCancelEditableRow (click)="onRowEditCancel(club, ri)">
          <mat-icon class="mw-reject-icon">clear</mat-icon>
        </button>-->

      </td>

    </tr>

  </ng-template>

  <ng-template pTemplate="rowexpansion" let-club>
    <div fxLayout="row-wrap" fxLayout.xs="column" fxLayoutAlign="space-between start">
      <!--RFID READERS-->
      <mat-card fxFlex="40" class="MatCard-mw">
        <mat-card-title fxFlexLayout="row-wrap" fxLayoutAlign="space-between center">
          <h6 style="margin:0px; padding:0px">Läsare:</h6>
          <div fxLayoutAlign="end" fxFlex fxLayoutGap="1rem">
            <button class="mw-addBtn-icon" (click)="onAddReader(club)" fxLayoutAlign="start start"><mat-icon class="mw-addIcon-icon">add</mat-icon></button>
          </div>
        </mat-card-title>
        <mat-card-content>
          <p-table #dtRFID [value]="club.rfidReaders" dataKey="id" editMode="row">
            <ng-template pTemplate="header">
              <tr fxFlexLayout="row" fxLayoutAlign="start center">
                <th fxFlex="100%" pSortableColumn="readerName">Namn <p-sortIcon field="readerName"></p-sortIcon></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rfidReaders let-editing="editing" let-ri="rowIndex">
              <tr   fxFlexLayout="row" fxLayoutAlign="start center"  [pEditableRow]="rfidReaders" >
                <td class="mw-small-tableRow"  fxFlex="100%" fxLayout fxLayoutAlign="start center">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <mat-form-field>
                        <input matInput type="text" [(ngModel)]="rfidReaders.readerName">
                      </mat-form-field>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{rfidReaders.readerName}}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td class="mw-small-tableRow" fxFlex fxLayoutAlign="start center">
                  <div fxFlex fxLayoutAlign="end center">
                    <button *ngIf="!editing" class="mw-editBtn-icon" pInitEditableRow  (click)="onRowEditInitRFID()">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button *ngIf="!editing" class="mw-deleteBtn-icon" (click)="onDeleteClubRFID(rfidReaders)">
                      <mat-icon class="mw-deleteIcon-icon">close</mat-icon>
                    </button>
                    <button *ngIf="editing" class="mw-okBtn-icon" pInitEditableRow pSaveEditableRow (click)="onRowEditSaveRFID(rfidReaders)">
                      <mat-icon class="mw-okIcon-icon">done</mat-icon>
                    </button>
                    <button *ngIf="editing" class="mw-deleteBtn-icon" pCancelEditableRow (click)="onRowEditCancelRFID(club)">
                      <mat-icon class="mw-deleteIcon-icon">clear</mat-icon>
                    </button>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </mat-card-content>
      </mat-card>

      <!--LAP INFO-->
      <mat-card fxFlex="55" class="MatCard-mw">
        <mat-card-title fxFlexLayout="row-wrap" fxLayoutAlign="space-between center">
          <h6 style="margin:0px; padding:0px">Tillgängliga varv:</h6>
          <div fxLayoutAlign="end" fxFlex fxLayoutGap="1rem">
            <button class="mw-addBtn-icon" (click)="onAddLap(club)" fxLayoutAlign="start start"><mat-icon class="mw-addIcon-icon">add</mat-icon></button>
          </div>
   
        </mat-card-title>
        <mat-card-content>
          <p-table #dtLap [value]="club.laps" dataKey="lapId" editMode="row">
            <ng-template pTemplate="header">
              <tr fxFlexLayout="row" fxLayoutAlign="start center">
                <th fxFlex="50%" pSortableColumn="lapName">Namn <p-sortIcon field="lapName"></p-sortIcon></th>
                <th fxFlex pSortableColumn="type">Typ <p-sortIcon field="type"></p-sortIcon></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-laps let-editing="editing" let-ri="rowIndex">
              <tr fxFlexLayout="row" fxLayoutAlign="start center" [pEditableRow]="laps">
                <td class="mw-small-tableRow" fxFlex="50%" style="margin-left:1rem" fxLayoutAlign="start center">
                  {{laps.lapName}}
                </td>
                <td class="mw-small-tableRow" fxFlex fxLayoutAlign="start center">
                  {{laps.type}}
                </td>
                <td class="mw-small-tableRow" fxFlex fxLayoutAlign="start center">
                  <button *ngIf="!editing" class="mw-editBtn-icon" pInitEditableRow (click)="onRowEditInitLap(laps, club)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button class="mw-deleteBtn-icon" pCancelEditableRow (click)="onDeleteLap(laps)">
                    <mat-icon class="mw-deleteIcon-icon">clear</mat-icon>
                  </button>

                </td>
              </tr>
            </ng-template>
          </p-table>
        </mat-card-content>


      </mat-card>
    </div>
  </ng-template>
</p-table>
