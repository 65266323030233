<p-table #dt [value]="mcBrands" dataKey="id" editMode="row">
  <ng-template pTemplate="caption">
    <div fxLayout="row wrap" fxLayoutGap="1rem">
      <div fxLayoutAlign="flex-start" fxFlex.gt-xs="40" fxFlex.lt-sm="25">
        <mat-lable class="name-header-sm">
          Tillverkare
        </mat-lable>
      </div>

      <div fxLayoutAlign="end" fxFlex fxLayoutGap="1rem">
        <button class="mr-2 PrimaryBtn" style="height:2.5rem; margin-right:2rem; background-color:darkseagreen" mat-raised-button (click)="onAddBikeBrand()"><mat-icon>person_add</mat-icon></button>
      </div>
    </div>

  </ng-template>

    <ng-template pTemplate="header">
      <tr fxFlexLayout="row" fxLayoutAlign="start center">
        <th fxFlex="20%" pSortableColumn="bikeBrand">Märke <p-sortIcon field="bikeBrand"></p-sortIcon></th>
        <th fxFlex="20%" pSortableColumn="bikeBrand">Logo <p-sortIcon field="bikeBrand"></p-sortIcon></th>
        <th fxFlex="40%" pSortableColumn="bikeBrand">Url <p-sortIcon field="bikeBrand"></p-sortIcon></th>
        <th fxFlex ></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-mcBrand let-editing="editing" let-ri="rowIndex">
      <tr fxLayout="row" style="height:3rem" [pEditableRow]="mcBrand">
        <td fxFlex="20%" fxLayout fxLayoutAlign="start center">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <mat-form-field>
                <input matInput type="text" [(ngModel)]="mcBrand.bikeBrand">
              </mat-form-field>
            </ng-template>
            <ng-template pTemplate="output">
              {{mcBrand.bikeBrand}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td fxFlex="20%" fxLayout fxLayoutAlign="start center">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <mat-form-field>
                <ngx-mat-file-input [accept]="accept" placeholder="Välj en fil" [(ngModel)]="mcBrand.picture">
                  <mat-icon style="font-size:25px" ngxMatFileInputIcon>folder_open</mat-icon>
                </ngx-mat-file-input>
              </mat-form-field>
            </ng-template>
            <ng-template pTemplate="output">
              <img class="img" width="40" height="40" [src]='showImage(mcBrand.picture)' style="border-radius:10%;" />
            </ng-template>
          </p-cellEditor>
        </td>
        <td fxFlex="40%" fxLayout fxLayoutAlign="start center">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <mat-form-field>
                <input matInput type="text" [(ngModel)]="mcBrand.companyUrl">
              </mat-form-field>
            </ng-template>
            <ng-template pTemplate="output">
              {{mcBrand.companyUrl}}
            </ng-template>
          </p-cellEditor>
        </td>


        <td fxFlex fxLayoutAlign="start center">
          <button *ngIf="!editing" class="mw-btn" mat-raised-button pInitEditableRow (click)="onRowEditInit()">
            <mat-icon>edit</mat-icon>
          </button>
          <button *ngIf="editing" class="mw-btn" mat-raised-button pSaveEditableRow (click)="onRowEditSave(mcBrand)">
            <mat-icon class="mw-accept-icon">done</mat-icon>
          </button>
          <button *ngIf="editing" class="mw-btn" mat-raised-button pCancelEditableRow (click)="onRowEditCancel()">
            <mat-icon class="mw-reject-icon">clear</mat-icon>
          </button>
          <button *ngIf="!editing" class="mw-delete-btn" color="warn" mat-raised-button (click)="onDeleteClubRFID(mcBrand)">
            <mat-icon class="mw-delete-icon">person_remove</mat-icon>
          </button>
        </td>
      </tr>
    </ng-template>
</p-table>
