import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { User } from '../models/user';
import { ConfigService } from '../services/config.service';
import { IMember } from '../models/interfaceModels';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private tempUser: User;

  constructor(private fb: FormBuilder, private http: HttpClient, private config: ConfigService) { }

  configUrl = this.config.configUrl;

  register(user: User) {
    console.log(user)
    return this.http.post(this.configUrl +'Authentication/Register', user);
  }

  login(user : User) {
    return this.http.post(this.configUrl +'Authentication/Login', user);
  }

  //getUserProfile(user: User) {
  getUserProfile(user: IMember) {
    return this.http.post(this.configUrl +'Authentication/GetUserProfileDB', user);
  }
   
  getSvemoID(userID: string) {
    this.tempUser = new User('', userID, '', '', '', '', '', '')
    //alert(userID);
    return this.http.post(this.configUrl +'Authentication/GetSvemoID', this.tempUser);
  }

    

  forgotPassword(user: User) {
    alert('Are you stupid :-)');
    console.log(user);
   // return this.http.post('http://localhost:58338/api/Authentication/ForgotPassword', user);
  }

  roleMatch(allowedRoles): Boolean {
    var isMatch = false;
    if (localStorage.getItem('token') != null) {
      var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
      var userRole = payLoad.role;
      allowedRoles.forEach(element => {
        if (userRole == element) {
          isMatch = true;
          return false;
        }
      }); 
    }
    return isMatch;
  }
}
