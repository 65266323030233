<!--<p-table #dt [value]="events" dataKey="eventId"
         [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
         currentPageReportTemplate="Totalt {totalRecords} medlemmar" [rowsPerPageOptions]="[10,25,50]"
         sortMode="multiple"
         [scrollable]="true" scrollHeight="500px"
         [filterDelay]="0" [globalFilterFields]="['svemoId','firstName','lastName']"
         styleClass="p-datatable-members">


  HEADER
  <ng-template pTemplate="header">
    <tr fxFlexLayout="row" style="height:4rem">
      <th fxFlex="4rem"></th>

      <th fxFlex="15%">
        <mat-form-field>
          <mat-label>Välj klubb:</mat-label>
          <mat-select [formControl]="selectedClubs" multiple (selectionChange)="onClubChange($event)">
            <mat-option *ngFor="let club of dataRoom.clubs" [value]="club">{{club.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </th>
    </tr>
    <tr fxFlexLayout="row-wrap" style="height:4rem">
      <th fxFlex="4rem"></th>
      <th fxFlex="40px"></th>
      <th fxFlex="8rem" pSortableColumn="eventDate">Datum <p-sortIcon field="eventDate"></p-sortIcon></th>
      <th fxFlex="40%" pSortableColumn="eventName">Plats <p-sortIcon field="eventName"></p-sortIcon></th>


    </tr>
  </ng-template>-->


  <!--BODY-->
  <!--<ng-template pTemplate="body" let-event let-ri="rowIndex" let-expanded="expanded">


    <tr fxHide.lt-sm fxFlexLayout="row" style="height:3rem">
      <td fxFlex="5rem" fxLayout fxLayoutAlign="start center">
        <button type="button" style="margin:0px"
                pButton pRipple [pRowToggler]="event"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                (click)="expandCommand(event, expanded)">
        </button>
      </td>


    </tr>

  </ng-template>

  <ng-template pTemplate="rowexpansion" let-member>
    <div fxLayout="row-wrap" fxLayout.xs="column" fxLayoutAlign="space-between start">
      Bla

    </div>
  </ng-template>
</p-table>-->










<p-table #dt [value]="events" dataKey="eventId"
         [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
         currentPageReportTemplate="Totalt {totalRecords} träningar" [rowsPerPageOptions]="[10,25,50]"
         sortMode="multiple"
         [scrollable]="true" scrollHeight="500px"
         [filterDelay]="0" [globalFilterFields]="['eventId','eventName']"
         styleClass="p-datatable-members">

  <!--HEADER-->
  <ng-template pTemplate="header">
    <tr fxFlexLayout="row" style="height:4rem">
      <th fxFlex="4rem"></th>

      <th fxFlex="15%">
        <mat-form-field>
          <mat-label>Välj klubb:</mat-label>
          <mat-select [formControl]="selectedClubs" multiple (selectionChange)="onClubChange($event)">
            <mat-option *ngFor="let club of dataRoom.clubs" [value]="club">{{club.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </th>
    </tr>
    <tr fxFlexLayout="row-wrap" style="height:4rem">
      <th fxFlex="4rem"></th>
      <th fxFlex="40px"></th>
      <th fxFlex="8rem" pSortableColumn="eventDate">Datum <p-sortIcon field="eventDate"></p-sortIcon></th>
      <th fxFlex="40%" pSortableColumn="eventName">Plats <p-sortIcon field="eventName"></p-sortIcon></th>
    </tr>
  </ng-template>
  <!--BODY-->
  <ng-template pTemplate="body" let-event let-ri="rowIndex" let-expanded="expanded">
    <!--LARGE SCREEN-->
    <tr fxFlexLayout="row" style="height:3rem">
      <td fxFlex="4rem" fxLayout fxLayoutAlign="start center">
        <button type="button" style="margin:0px"
                pButton pRipple [pRowToggler]="event"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                (click)="expandCommand(event, expanded)">
        </button>
      </td>
      <td fxFlex="40px" fxLayout fxLayoutAlign="center center">
        <img class="img" width="40" height="40" [src]='dataRoom.showClubPic(event.clubModelId)' style="border-radius:20%" />
      </td>
      <td fxFlex="8rem">{{event.eventDate}}</td>
      <td fxFlex>{{event.eventName}}</td>
    </tr>

  </ng-template>

  <!--EXPANSION-->
  <ng-template pTemplate="rowexpansion" let-event>
    <div>
      {{event.eventId}} ERROR
      BLAA
      <p-table #dtexpand [value]="event.lapTimeModel">

        <ng-template pTemplate="body" let-lapTimeModel>
          <tr fxFlexLayout="row" style="height:3rem">
            <td fxFlex>{{lapTimeModel.svemoId}}</td>
          </tr>
        </ng-template>

      </p-table>
    </div>
  </ng-template>


</p-table>
