import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { DbService } from '../../../services/db.service';
import { IBikeBrand, IMember, IClub } from '../../../models/interfaceModels';
import { IupdateUrlMember } from '../member-profile/member-profile.component';
import { waitForAsync } from '@angular/core/testing';

export interface AddDialogData {

}

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.css']
})


export class AddMemberComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AddMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddDialogData, private fb: FormBuilder, private dbService: DbService) { }

  public member: IMember;
  public bikeBrands: IBikeBrand[];
  public clubs: IClub[];

  public pictureUrl: File;

  public accept = '.jpg, .png'
  public multiple = false;

  private updateUrlMember: IupdateUrlMember;

  ngOnInit(): void {
    this.dbService.broadCastBikeBrands.subscribe(data => {
      this.bikeBrands = data;
    })
    this.dbService.broadCastClubs.subscribe(data => {
      this.clubs = data;
    })
    this.dbService.getBikeBrands();
    this.dbService.getClubs();
  }

  addMemberForm = this.fb.group({
    svemoId: [, [Validators.required]],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    bikeBrandModelId: [0,],
    clubModelId: [0, [Validators.required]],
    pictureUrl: [''],
  })

  onNoClick() {
    this.dialogRef.close();
  }

  async onAddClick() {
    
    if (this.addMemberForm.valid) {
      var picUrl = "";
      if (this.addMemberForm.value.pictureUrl) {
        this.updateUrlMember = {
          svemoId: this.addMemberForm.value.svemoId,
          PictureUrl: this.addMemberForm.value.pictureUrl
        }
        
        picUrl = "exists";
      }
      else {
        picUrl = "/assets/img/profile.png"
        this.updateUrlMember = {
          svemoId: this.addMemberForm.value.svemoId,
          PictureUrl: null
        }
      }

      this.member = {
        svemoId: this.addMemberForm.value.svemoId,
        firstName: this.addMemberForm.value.firstName,
        lastName: this.addMemberForm.value.lastName,
        clubModel: null,
        clubModelId: +this.addMemberForm.value.clubModelId,
        bikeBrandModel: null,
        bikeBrandModelId: +this.addMemberForm.value.bikeBrandModelId,
        pictureUrl: picUrl,
        picture: [],
        confirmedTimeReadModel: null,
        intermediateTimeModel: null,
        lapTimeModel: null,
      }
     
      this.dbService.addMember(this.member, this.updateUrlMember)

      
      this.dialogRef.close();
    }
    else
      alert('Form is not valid')
  }
}
