<body>

  <div fxLayout="row" fxLayout.xs="column" class="header-name">
    <div fxFlex="100%" fxLayoutAlign="center center" class="child-1"><h2>{{data.member.firstName}} {{data.member.lastName}}</h2></div>
    <button mat-icon-button class="mw-mat-close-button" [mat-dialog-close]="true">
      <mat-icon class="mw-mat-close-icon">close</mat-icon>
    </button>
  </div>
  <div fxLayout="row" fxLayout.xs="column" style="padding:5px" fxLayoutGap="10px" fxLayoutAlign="space-between">
    <div fxFlex="50%" fxLayoutAlign="start center">
      <img style="max-height:450px; max-width:100%; border-radius:2% " [src]='showImage()' />
    </div>
    <div fxFlex="50%" fxLayoutAlign="start start" class="mw_card">
      <div fxLayout="column" style="padding:5px;" fxLayoutGap="10px">
        <div fxLayout="row" style="padding:5px" fxLayoutGap="10px">
          <span>Klubb:  </span>
          <span>{{dataRoom.showClubName(data.member.clubModelId)}}</span>
        </div>
        <div fxLayout="row" style="padding:5px" fxLayoyGap="10px">
          <span>Motorcykel:  </span>
          <span>{{dataRoom.showBikeBrand(data.member.bikeBrandModelId)}}</span>
        </div>
        <div *ngIf="data.member.svemoId == data.activeUser.svemoId || data.userRole == 'Admin'" fxLayout="row" style="padding:5px" fxLayoutGap="10px">
          <mat-form-field>
            <ngx-mat-file-input [multiple]="multiple" [accept]="accept" placeholder="Välj en fil" [(ngModel)]="pictureUrl">
              <mat-icon style="font-size:25px" ngxMatFileInputIcon>folder_open</mat-icon>
            </ngx-mat-file-input>
            <mat-hint align="start"> Uppdatera profilbild </mat-hint>
          </mat-form-field>
        </div>
        <button class="mr-2 PrimaryBtn" color="primary" mat-raised-button
                style="height:40px; margin-left:10px" (click)="onUpdatePicUrl()">
          Uppdatera <mat-icon>published_with_changes</mat-icon>
        </button>
      </div>
    </div>
  </div>
</body>


