import { Component, OnInit, ViewChild } from '@angular/core';
import { IClub, IMember, IRFIDReader, ILap } from '../../../../models/interfaceModels';
import { Table } from 'primeng/table';
import { DbService } from '../../../../services/db.service';
import { AuthenticationService } from '../../../../auth/authentication.service';
import { ActiveuserService } from '../../../../services/activeuser.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import { AddLapComponent } from './Dialogs/add-lap/add-lap.component';
import { EditLapComponent } from './Dialogs/edit-lap/edit-lap.component';


@Component({
  selector: 'app-club-info',
  templateUrl: './club-info.component.html',
  styleUrls: ['./club-info.component.scss'],
  providers: [MessageService],
})
export class ClubInfoComponent implements OnInit {


  public clubs: IClub[];

  public activeuser: IMember;
  tokenExists;
  userRole;

  @ViewChild('dt') table: Table;
  @ViewChild('dtRFID') tableRFID: Table;
  @ViewChild('dtLap') tableLap: Table;

  constructor(private dbService: DbService,
    private router: Router,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    //Start subscribing to available clubs
    this.dbService.broadCastClubs.subscribe(data => {
      this.clubs = data;
    })

    this.dbService.getClubs();
  }

  onAddClub() {
    var newClub:IClub = {
      clubModelId: 0,
      name: "",
      address: "",
      pictureUrl: "",
      picture: null,
      rfidReaders: [],
      laps: [],
    }
    this.clubs.push(newClub)
    this.table.initRowEdit(newClub)
  }





  onDeleteClub(deleteClub:IClub) {
    this.dbService.removeClub(deleteClub);
  }
  onRowEditInit(club: any) {

  }
  onRowEditSave(club: IClub) {
    // START TO CLEAN UP club Object
    var picFile: any;
    console.log(club)
    if (club.picture) {
      if (club.picture.name) {
        picFile = club.picture;
        club.pictureUrl = 'exists';
        club.picture = null;
      }
    }
    else {
      picFile = null;
      club.pictureUrl = "/assets/img/profile.png" 
    }
    if (club.clubModelId == 0)
      this.dbService.addClub(club, picFile);
    else
      this.dbService.updateClub(club, picFile)
  }
  onRowEditCancel(club: IClub, index: number) {
    //this.products2[index] = this.clonedProducts[product.id];
    //delete this.products2[product.id];
  }


  onAddReader(editClub: IClub) {
    var newReader: IRFIDReader = {
      id: 0,
      readerName: "",
      clubModelId: editClub.clubModelId,
      isActive: false
    }
    this.clubs[this.clubs.findIndex(club => club.clubModelId == editClub.clubModelId)].rfidReaders.push(newReader)
    this.tableRFID.initRowEdit(newReader)
  }
  onRowEditInitRFID() {

  }
  onRowEditSaveRFID(rfidReader: IRFIDReader) {
    if (rfidReader.id == 0)
      this.dbService.addRFIDReader(rfidReader)
    else
      this.dbService.updateRFIDReader(rfidReader)
  }
  onRowEditCancelRFID(clubCancel: IClub) {
    this.dbService.getClubs();
  }
  onDeleteClubRFID(rfidReader: IRFIDReader) {
    this.dbService.removeRFIDReader(rfidReader)
  }

  onAddLap(editClub: IClub) {
    const dialogRef = this.dialog.open(AddLapComponent, {
      width: '80%',
      height: '500px',
      panelClass: 'custom-dialog-container',
      data: { editClub }
    });
    dialogRef.afterClosed().subscribe(result => {
      
      console.log('The dialog was closed');
    });
  }

  onRowEditInitLap(lap:ILap, club:IClub) {
   
    const dialogRef = this.dialog.open(EditLapComponent, {
      width: '80%',
      height: '500px',
      panelClass: 'custom-dialog-container',
      data: { lap, club }
    });
    dialogRef.afterClosed().subscribe(result => {

      console.log('The dialog was closed');
    });
  }


  onRowEditCancelLap() {
    
  }
  onDeleteLap(lap:ILap) {
    this.dbService.removeLap(lap.lapId)
  }

  showImage(image: any) {
    if (image) {
      let objectURL = 'data:image/jpeg;base64,' + image;
      var Url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    }
    else {
      Url = "/assets/img/nologo.png"
    }
    return Url;
  }
}
