import { Component, OnInit } from '@angular/core';
import { IMember, IEvent, IConfirmedTimeRead } from '../../../models/interfaceModels';
import { ActiveuserService } from '../../../services/activeuser.service';
import { DbService } from '../../../services/db.service';
import { SignalRService } from '../../../services/signal-r.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-live-training',
  templateUrl: './live-training.component.html',
  styleUrls: ['./live-training.component.css']
})
export class LiveTrainingComponent implements OnInit {


  public userRole;
  tokenExists;

  constructor(private activeUserService: ActiveuserService,
    private dbService: DbService,
    private sanitizer: DomSanitizer,
    private signalR : SignalRService) { }

  public memberTimes: IMember[] = [];

  public events: IEvent[];
  public selectedEvent: IEvent;

  public maxLaps: number = 0;
  public headers: [{ title: string }];

  public statusColors = [{ status: "Start", color: "green" }, { status: "End", color: "black" },
    { status: "Intermediate", color: "green" }, { status: "Unknown", color: "red" }]

  public signalRBroadCast: any;
  public dbBroadCastMemberTimesEvent: any;
  public dbBroadCastIntermediateTime: any;
  public dbBroadCastConfirmedTimes: any;
  public dbBroadCastLapTimes: any;

  ngOnInit(): void {
    if (localStorage.getItem('token') != null) {
      this.tokenExists = true;
      var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
      this.userRole = payLoad.role;
    }
    else {
      this.tokenExists = false
      this.userRole = "Guest";
    }

    //this.headers = [{ title: 'Namn' }];
    this.dbService.broadCastEvents.subscribe(data => {
      console.log(data)
      this.events = data.filter(event => event.hasStarted == 1 && event.eventType.type == 'Träning');
    });
    this.dbService.getEvents();
  }

  ngOnDestroy(): void {
    this.signalR.stoplistenForEventData();
    if (this.signalRBroadCast)
      this.signalRBroadCast.unsubscribe();
    if (this.dbBroadCastMemberTimesEvent)
      this.dbBroadCastMemberTimesEvent.unsubscribe();
  }
  onEventChange(event) {
    //MAKE SURE NO SIGNALR EXISTS THAT IS LISTENING TO EVENT DATA
    this.signalR.stoplistenForEventData();

    this.selectedEvent = event.value;
    this.memberTimes = null;
    this.headers = null;

    //Listenes if all data is reloded
    if (!this.dbBroadCastMemberTimesEvent)
      this.dbBroadCastMemberTimesEvent = this.dbService.broadCastMembersTimesEvent.subscribe(data => {
        if (data) {
        console.log(data)
        //Create headers for all laps
        data.forEach(member => {
          // If this member has more laps then allready added to header add new headers
          if (member.lapTimeModel.length > this.maxLaps) {
            var addedLaps = member.lapTimeModel.length;
            while (this.maxLaps < member.lapTimeModel.length) {
              if (!this.headers)
                this.headers = [{ title: 'Lap ' + (this.maxLaps + 1) }];
              else
                this.headers.push({ title: 'Lap ' + (this.maxLaps + 1) });
              this.maxLaps++;
            }
          }
        })
      }
      console.log('All member times are updated')
      this.memberTimes = data;
    })
    this.dbService.getMembersTimesEvent(event.value);

    //Listen if Intermediate times are loaded
    //Start signalR to listen for changes for this event
    //Check if a method for waiting for SignalR data has been started
    
    this.signalR.listenForEventData(this.selectedEvent)
    //Recieves data from a signalR call
 
    this.signalRBroadCast = this.signalR.broadCastnewEventData.subscribe(data => {
      //Check if racer exists
      console.log("SignalR Broadcast")
      var racerIdx = this.memberTimes.findIndex(member => member.svemoId == data.svemoId);

      if (!this.dbBroadCastConfirmedTimes)
        this.dbBroadCastConfirmedTimes = this.dbService.broadCastConfirmedTimeReads.subscribe(dataConfTime => {
          if (dataConfTime) {
            this.memberTimes[this.memberTimes.findIndex(member => member.svemoId == dataConfTime[0].svemoId)].confirmedTimeReadModel = dataConfTime;
            //this.memberTimes[racerIdx].confirmedTimeReadModel = data;
            console.log(dataConfTime)
            if (dataConfTime[dataConfTime.length - 1].status != "Start")
              this.dbService.getMemberIntermediatesEvent(this.selectedEvent.eventId, data.svemoId);
            if (dataConfTime[dataConfTime.length - 1].status == "End")
              this.dbService.getMemberLapTimesEvent(this.selectedEvent.eventId, data.svemoId);
          }
        })

      //If racer exists get intermediates and laptimes and add to memberTimes
      if (!this.dbBroadCastIntermediateTime)
        this.dbBroadCastIntermediateTime = this.dbService.broadCastIntermediateTimes.subscribe(dataIntTime => {
          console.log('Broadcast of Intermediate times')
          if (dataIntTime) {
            this.memberTimes[this.memberTimes.findIndex(member => member.svemoId == dataIntTime[0].svemoId)].intermediateTimeModel = dataIntTime;
            //this.memberTimes[racerIdx].intermediateTimeModel = data;
          }
        })
      
      if (!this.dbBroadCastLapTimes)
        this.dbBroadCastLapTimes = this.dbService.broadCastLapTimes.subscribe(dataLapTimes => {
          console.log('BroadCast LapTimeData')
         // CONTINUE HERE SOME ISSUE WHEN RUNNING LAP WITH INTERMEDIATE
          if (dataLapTimes) {
            this.memberTimes[this.memberTimes.findIndex(member => member.svemoId == dataLapTimes[0].svemoId)].lapTimeModel = dataLapTimes;
          //this.memberTimes[racerIdx].lapTimeModel = data;
          this.memberTimes.forEach(member => {
            // If this member has more laps then allready added to header add new headers
            if (member.lapTimeModel.length > this.maxLaps) {
              var addedLaps = member.lapTimeModel.length;
              while (this.maxLaps < member.lapTimeModel.length) {
                if (!this.headers)
                  this.headers = [{ title: 'Lap ' + (this.maxLaps + 1) }];
                else
                  this.headers.push({ title: 'Lap ' + (this.maxLaps + 1) });
                this.maxLaps++;
              }
            }
          })
   
        }
      })

      if (racerIdx != -1) {
        console.log('Retrieves time values for racer: ' + data.svemoId)
        
        
        
        this.dbService.getMemberConfirmedTimeReadsEvent(this.selectedEvent.eventId, data.svemoId);
      }
      //If not get the full member with all times!
      else {
        console.log('Retrieves ALL Member data in Event')
        this.dbService.getMembersTimesEvent(this.selectedEvent);
      } 
    })
  }

  getLapName(lapId: number) : string {
    var lap = this.selectedEvent.lapinEvent.find(data => data.lapId == lapId);
    return lap.lapModel.lapName;
  }
  showImage(memberPic: any) {
    if (memberPic) {
      let objectURL = 'data:image/jpeg;base64,' + memberPic;
      var Url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      return Url;
    }
    else
      return '/assets/img/profile.png'
  }
  convertToMiniTime(timeString: string): string {
    var noMilliSec = timeString.split(".").slice(0.1);
    var noHour = noMilliSec[0].split(":");
    return noHour[1] + ":" + noHour[2];
  }
  getStatusColor(timeReads: IConfirmedTimeRead[]) {
    if (timeReads) {
      var lastRead = timeReads[timeReads.length - 1].status;
      return this.statusColors.filter(item => item.status == lastRead)[0].color
    }
    else
      return 'pink'
  }


  writeConsole() {

    console.log(this.signalR)
    console.log(this.dbService)
  }
}
