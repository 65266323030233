import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MaterialModule } from './material/material.module';
import { LayoutComponent } from './layout/layout.component';

import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './navigation/header/header.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthenticationService } from './auth/authentication.service';
import { ActiveuserService } from './services/activeuser.service';
import { AuthInterceptor } from './auth/auth.interceptor';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DbMembersComponent } from './pages/admin/db-members/db-members.component';
import { DbEventsComponent } from './pages/admin/db-events/db-events.component';
import { DbInfoComponent } from './pages/admin/db-info/db-info.component';

import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { DummyComponent } from './pages/dummy/dummy.component';
import { ProductService } from './productservice';
import { MemberProfileComponent } from './pages/dialogs/member-profile/member-profile.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { AddMemberComponent } from './pages/dialogs/add-member/add-member.component';
import { ClubInfoComponent } from './pages/admin/db-info/club-info/club-info.component';
import { LapInfoComponent } from './pages/admin/db-info/lap-info/lap-info.component';
import { McInfoComponent } from './pages/admin/db-info/mc-info/mc-info.component';
import { SeriesInfoComponent } from './pages/admin/db-info/series-info/series-info.component';
import { ButtonModule } from 'primeng/button';
import { AddLapComponent } from './pages/admin/db-info/club-info/Dialogs/add-lap/add-lap.component';
import { EditLapComponent } from './pages/admin/db-info/club-info/Dialogs/edit-lap/edit-lap.component';
import { AddEventComponent } from './pages/admin/db-events/dialogs/add-event/add-event.component';
import { EditEventComponent } from './pages/admin/db-events/dialogs/edit-event/edit-event.component';
import { TrainingStartComponent } from './pages/admin/training-start/training-start.component';
import { LiveTrainingComponent } from './pages/results/live-training/live-training.component';
import { OldTrainingComponent } from './pages/results/old-training/old-training.component';
import { LiveCompetitionComponent } from './pages/results/live-competition/live-competition.component';
import { OldCompetitionComponent } from './pages/results/old-competition/old-competition.component';
import { ByMemberComponent } from './pages/results/old-training/by-member/by-member.component';
import { ByTrainingComponent } from './pages/results/old-training/by-training/by-training.component';
import { PrepareCompetitionComponent } from './pages/admin/prepare-competition/prepare-competition.component';
import { RunCompetitionComponent } from './pages/admin/run-competition/run-competition.component';
import { ClientStatusComponent } from './pages/admin/client-status/client-status.component';





@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HomeComponent,
    HeaderComponent,
    SidenavListComponent,
    LoginComponent,
    RegisterComponent,
    DbMembersComponent,
    DbEventsComponent,
    DbInfoComponent,
    DummyComponent,
    MemberProfileComponent,
    ConfirmDialogComponent,
    AddMemberComponent,
    ClubInfoComponent,
    LapInfoComponent,
    McInfoComponent,
    SeriesInfoComponent,
    AddLapComponent,
    EditLapComponent,
    AddEventComponent,
    EditEventComponent,
    TrainingStartComponent,
    LiveTrainingComponent,
    OldTrainingComponent,
    LiveCompetitionComponent,
    OldCompetitionComponent,
    ByMemberComponent,
    ByTrainingComponent,
    PrepareCompetitionComponent,
    RunCompetitionComponent,
    ClientStatusComponent,

 

  ],
  imports: [
    FlexLayoutModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MaterialModule,
    NgxMatFileInputModule,

    FormsModule,
    ReactiveFormsModule,

    HttpClientModule,
    //PRIMENG MODULES
    TableModule,
    MultiSelectModule,
    InputTextModule,
    ButtonModule,

  ],
  providers: [
    ProductService,
    ActiveuserService,
    AuthenticationService, {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
