import { Component, OnInit } from '@angular/core';
import { DbService } from '../../../services/db.service';

@Component({
  selector: 'app-old-training',
  templateUrl: './old-training.component.html',
  styleUrls: ['./old-training.component.css']
})
export class OldTrainingComponent implements OnInit {

  constructor(private dbService : DbService) { }

  ngOnInit(): void {
   // this.dbService.getEvents();
    this.dbService.getEvents();
  }

}
