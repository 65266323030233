import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DbService } from '../../../../../services/db.service';
import { FormBuilder, Validators } from '@angular/forms';
import { IClub, IEventType, ILap, IEvent, ILapInEvent } from '../../../../../models/interfaceModels';

@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.css']
})
export class EditEventComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EditEventComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dbService: DbService) { }

  public clubs: IClub[];
  public selectedClub: IClub;

  public eventTypes: IEventType[];
  public selectedEventType: IEventType[];

  public Laps: ILap[];
  public LapsinEvent: ILap[];
  public selectedLap: ILap;
  public selectedEventLap: ILap;

  addEventForm = this.fb.group({
    id: [this.data.editEvent.eventId],
    eventDate: [this.data.editEvent.eventDate, [Validators.required]],
    eventName: [this.data.editEvent.eventName, [Validators.required]],
    clubModelId: [this.data.editEvent.clubModelId, [Validators.required]],
    eventTypeId: [this.data.editEvent.eventTypeId, [Validators.required]],
    hasStarted: [this.data.editEvent.hasStarted],
    weatherData: [''],
    temperature: [''],
    laps: [[], Validators.required]
  })

  ngOnInit(): void {
    this.selectedClub = this.data.club;
    this.LapsinEvent = [];
    this.dbService.broadCastClubs.subscribe(data => {
      this.clubs = data;
    });
    this.dbService.broadCastEventTypes.subscribe(data => {
      this.eventTypes = data;
    });

    this.dbService.getClubs();
    this.dbService.getEventTypes();
  }

  lapInEventCreate(laps: ILap[]): ILapInEvent[] {
    var lapsInEvent: ILapInEvent[];
    lapsInEvent = [];
    laps.forEach(lap => {
      var lapInEvent: ILapInEvent = {
        lapId: lap.lapId,
        lapModel: null,
        eventId: 0,
        eventModel: null,
      };
      lapsInEvent.push(lapInEvent);
    })
    return lapsInEvent;
  }

  onClubChange(event) {
    this.clubs.forEach(club => {
      if (club.clubModelId == event.value) { this.selectedClub = club; }
    })
    this.LapsinEvent = [];
  };

  onUpdateClick() {
    if (this.addEventForm) {
      var event: IEvent = {
        eventId: this.addEventForm.value.eventId,
        eventDate: this.addEventForm.value.eventDate,
        eventName: this.addEventForm.value.eventName,
        club: null,
        clubModelId: +this.addEventForm.value.clubModelId,
        eventType: null,
        eventTypeId: +this.addEventForm.value.eventTypeId,
        hasStarted: this.addEventForm.value.hasStarted,
        weatherData: this.addEventForm.value.weatherData,
        temperature: this.addEventForm.value.temperature,
        memberInEvent: null,
        memberInCompetition: null,
        timeReadModel: null,
        confirmedTimeReadModel: null,
        intermediateTimeModel: null,
        lapTimeModel: null,
        lapinEvent: this.lapInEventCreate(this.addEventForm.value.laps),
        seriesModel: null,
        classesInEvent: null,
        startType: null,
        startTypeId: this.addEventForm.value.startTypeId,
      }

      this.dbService.updateEvent(event);
      this.dialogRef.close();
    }


  }

  onNoClick() {
    this.dialogRef.close();
  }

}
