<div fxLayout="row wrap" fxLayoutAlign="center center">
  <mat-card fxFlex="50%" fxFlex.lt-md="60%" fxFlex.lt-sm="100%" class="MatCard">
    <mat-card-header>
      <mat-card-title>
        Logga in:
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="userLogin">
        <div class="row">
          <mat-form-field class="full-width">
            <input matInput placeholder="Svemo ID:" formControlName="username">
            <mat-error *ngIf="userLogin.controls['username'].hasError('required')">
              Svemo ID <strong>måste anges</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <br/>
        <div class="row">
          <mat-form-field class="full-width">
            <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="password" required>
            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="userLogin.controls['password'].hasError('required')">
              Lösenord <strong>måste anges</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions fxLayoutAlign="center center">
      <button class="mr-2 PrimaryBtn" mat-raised-button  (click)="onLogin()">Logga in</button>
      <button class="mr-2 PrimaryBtn" mat-raised-button  routerLink="/auth/register">Registrera</button>
      <button class="mr-2 PrimaryBtn" mat-raised-button >Glömt lösenord</button>
    </mat-card-actions>
  </mat-card>

</div>

