import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from '../../auth/authentication.service';
import { ActiveuserService } from '../../services/activeuser.service';
import { Router } from '@angular/router';
import { IMember } from '../../models/interfaceModels';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.css']
})
export class SidenavListComponent implements OnInit {
  @Output() sidenavClose = new EventEmitter();

  activeuser: IMember;
  userRole;
  tokenExists;

  profilePic: any;

  constructor(public authService: AuthenticationService,
    private activeUserService: ActiveuserService,
    private router: Router,
    private sanitizer: DomSanitizer,) { }

  ngOnInit(): void {
    this.activeUserService.currentUser.subscribe(user => {
      this.activeuser = user
      if (localStorage.getItem('token') != null) {
        var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
        this.userRole = payLoad.role;
        this.tokenExists = true;
        if (user.picture.length > 0) {
          let objectURL = 'data:image/jpeg;base64,' + user.picture;
          var Url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.profilePic = Url;
        }
        else
          this.profilePic = '/assets/img/profile.png'
      }
    })
  }
  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }

  onLogout() {
    this.activeUserService.activeAsGuest();
    localStorage.removeItem('token');
    this.userRole = "";
    this.tokenExists = false
    this.router.navigate(['/home']);
    this.sidenavClose.emit();
  }
}
