import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-old-competition',
  templateUrl: './old-competition.component.html',
  styleUrls: ['./old-competition.component.css']
})
export class OldCompetitionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
