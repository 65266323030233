import { Component, OnInit, ViewChild } from '@angular/core';
import { DbService } from '../../../services/db.service';
import { IMember, IClub, IBikeBrand } from '../../../models/interfaceModels';
import { Table } from 'primeng/table';
import { HostListener } from "@angular/core";
import { FormControl } from '@angular/forms';
import { ActiveuserService } from '../../../services/activeuser.service';
import { AuthenticationService } from '../../../auth/authentication.service';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MemberProfileComponent } from '../../dialogs/member-profile/member-profile.component';
import { AddMemberComponent } from '../../dialogs/add-member/add-member.component';
import { DomSanitizer } from '@angular/platform-browser';
import { DataRoomService } from '../../../services/data-room.service';

@Component({
  selector: 'app-db-members',
  templateUrl: './db-members.component.html',
  styleUrls: ['./db-members.component.scss']
})

export class DbMembersComponent implements OnInit {


  public members: IMember[];
 
  public clubs: IClub[] = [];
  public BikeBrands: IBikeBrand[] = [];

  screenHeight: number;
  screenWidth: number;
  selectedClubs? = new FormControl();

  public activeuser: IMember;
  tokenExists;
  userRole;

  @ViewChild('dt') table: Table;

 
  onResize(event?) {
    this.screenHeight = window.innerHeight-350;
    this.screenWidth = window.innerWidth;
  }

  constructor(private dbService: DbService,
    public authService: AuthenticationService,
    private activeUserService: ActiveuserService,
    private router: Router,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    public dataRoom:DataRoomService,
  ) { this.onResize(); }

  ngOnInit(): void {

    //this.dbService.broadCastBikeBrands.subscribe(data => {
    //  this.BikeBrands = data;
    //  this.dbService.getMembers();
    //})
    //this.dbService.broadCastClubs.subscribe(data => {
    //  this.clubs = data;
    //  this.dbService.getBikeBrands();
    //})
    this.dbService.getMembers();
    // Starts to subscribe to members will update list if changed
    this.dbService.broadCastMembers.subscribe(data => {
      this.members = data;
      //data.forEach(member => {
      //  var exist = this.clubs.findIndex(club => club.clubModelId == member.clubModel.clubModelId);
      //  if (exist== -1)
      //    this.clubs.push(member.clubModel)
      //})
    })

    this.activeUserService.currentUser.subscribe(user => {
      this.activeuser = user
      if (localStorage.getItem('token') != null) {
        var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
      }
    })

    if (localStorage.getItem('token') != null) {
      this.tokenExists = true;
      var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
      this.authService.getSvemoID(payLoad.UserId).subscribe(
        (res: any) => {
          this.activeUserService.changeUser(res)
        }
      )
      this.userRole = payLoad.role;
    }
    else {

      this.activeUserService.activeAsGuest();
      this.tokenExists = false
      this.userRole = "";
    }
    // Retrieves initial member list, triggers a broadcastMembers!!

    this.dbService.getClubs(); 
  }

  onClubChange(event) {
    var filterClubIds = [];
    event.value.forEach(data => filterClubIds.push(data.clubModelId))
    this.table.filter(filterClubIds, 'clubModelId', 'in')
  }

  onMemberDelete(member) {
    this.dbService.removeMember(member);
  }

  onViewMember(selected_member) {
    const dialogRef = this.dialog.open(MemberProfileComponent, {
      width: '100%',
      maxHeight: '500px',
      data: { member: selected_member, userRole: this.userRole, activeUser: this.activeuser },
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  onAddMember() {
    const dialogRef = this.dialog.open(AddMemberComponent, {
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  showImage(memberPic: any) {
    if (memberPic) {
      let objectURL = 'data:image/jpeg;base64,' + memberPic;
      var Url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      return Url;
    }
    else
      return '/assets/img/profile.png'
  }

  //showImageBikeBrand(member: IMember) {
  //  if (this.BikeBrands) {
  //    var bikePic = this.BikeBrands.find(data => data.id == member.bikeBrandModelId).picture;
  //    if (bikePic.length > 0) {
  //      let objectURL = 'data:image/jpeg;base64,' + bikePic;
  //      var Url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //      return Url;
  //    }
  //    else
  //      return '/assets/img/profile.png'
  //  }
  //  else
  //    return '/assets/img/profile.png'
  //}
  //showBikeBrandName(member: IMember) {
    
  //  return this.BikeBrands.find(data => data.id == member.bikeBrandModelId).bikeBrand
  //}

  //showClubName(clubId: number) {
  //  if (this.clubs)
  //    return this.clubs.find(data => data.clubModelId == clubId).name
  //}


  writeConsole() {
    console.log(this.selectedClubs)
  }
}

export interface member_dialog {
  member: IMember;
  userRole: any;
  activeUser: IMember;
}
