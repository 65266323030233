import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest, HttpErrorResponse  } from '@angular/common/http';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { IMember, IClub, ILap, ILapSetUp, IBikeBrand, IRFIDReader, IEventType, IEvent, IPi, ITimeRead, ISerie, IClass, IMemberInCompetition, IStartType, IIntermediateTimeModel, IConfirmedTimeRead, } from '../models/interfaceModels'; //IEventLap
import { ConfigService } from './config.service';
import { IupdateUrlMember } from '../pages/dialogs/member-profile/member-profile.component';
import { DomSanitizer } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})
export class DbService {

  constructor(private http: HttpClient,
    private config: ConfigService,
    private sanitizer: DomSanitizer,) { }

  configUrl = this.config.configUrl;

  //USED IN NEW CLIENT
  changedMembers = new ReplaySubject<IMember[]>(null);
  broadCastMembers = this.changedMembers.asObservable();

  //Retrieves all members in database including:
  //      clubModel
  //      bikeBrandModel
  getMembers() {
    this.http.get<IMember[]>(this.configUrl + 'Member/').subscribe(
      (data: IMember[]) => {
        this.changedMembers.next(data)
      }
    );
  }
  updateMemberProfileUrl(file: IupdateUrlMember) {
    
    //Get original filetype
    var fileType = file.PictureUrl.name.split('.');
    var lastIdx = fileType.lastIndexOf;
    const formData = new FormData();
    formData.append('file', file.PictureUrl, file.svemoId.toString() + '.' + fileType[fileType.length-1])

    this.http.post<boolean>(this.configUrl + 'Member/MemberUploadImage/', formData).subscribe(
      (data: boolean) => {
        if (data) 
          this.getMembers();    
        else
          alert('could not update picture!')
      })
  }
  addMember(member: IMember, file: IupdateUrlMember) {
    this.http.post<IMember>(this.configUrl + 'Member/', member).subscribe(
      (data: IMember) => {
        //this.changedMembers.next(data)
        if (member.pictureUrl == 'exists') {
          this.updateMemberProfileUrl(file);
        }
        else {
          this.getMembers();
        } 
      });
  }
  getMembersByClub(clubModelId: number) {
    this.http.get<IMember[]>(this.configUrl + 'Member/ByClub/' + clubModelId).subscribe(
      (data: IMember[]) => this.changedMembers.next(data)
    );
  }
  updateMember(member: IMember) {
    this.http.put<IMember>(this.configUrl + 'Member/' + member.svemoId, member).subscribe(
      (data: IMember) => {
        //this.changedMembers.next(data)
        alert(member.firstName + ' ' + member.lastName + ' uppdaterades i databasen');
        this.getMembers();
      });
  }
  removeMember(member: IMember) {
    this.http.delete<IMember>(this.configUrl + 'Member/' + member.svemoId).subscribe(
      (data: IMember) => {
        //this.changedMembers.next(data)
        alert(member.firstName + ' ' + member.lastName + ' raderades från databasen');
        this.getMembers();
      });
  }

  // RETIREVES PROFILEPICTURE
  //changedProfilePic = new ReplaySubject<any>(1);
  //broadCastProfilePic = this.changedProfilePic.asObservable();
  //getProfilePic(svemoId: number) {
  //  this.http.get<any>(this.configUrl + 'MemberDetails/' + svemoId).subscribe(
  //    (data: any) => {
  //      if (data)

  //        this.changedProfilePic.next(data.picture)
  //      });
  //}
  getProfilePic(svemoId: number): Observable<any> {
    console.log('dbReadPic')
    return this.http.get<any>(this.configUrl + 'MemberDetails/' + svemoId)
  }

  // CLUB MOD
  private changedClubs = new ReplaySubject<IClub[]>(null);
  broadCastClubs = this.changedClubs.asObservable();
  getClubs() {
    this.http.get<IClub[]>(this.configUrl + 'Club/').subscribe(
      (data: IClub[]) => {
        this.changedClubs.next(data)
      });
  }
  updateClubPictureUrl(club:IClub, picFile:any) {
    //Get original filetype

    var fileType = picFile.name.split('.');
    var lastIdx = fileType.lastIndexOf;
    const formData = new FormData();
    formData.append('file', picFile, club.name + '.' + fileType[fileType.length - 1])

    this.http.post<boolean>(this.configUrl + 'Club/ClubUploadImage/', formData).subscribe(
      (data: boolean) => {
        if (data)
          this.getClubs();
        else
          alert('could not update picture!')
      })
  }
  addClub(club: IClub, picFile : any) {
    this.http.post<IClub[]>(this.configUrl + 'Club/', club).subscribe(
      (data: IClub[]) => {
        if (club.pictureUrl == 'exists') {
          this.updateClubPictureUrl(club, picFile)
        }
        else {
          this.changedClubs.next(data)
        }  
    });

  }
  updateClub(club: IClub, picFile: any) {

    this.http.put<IClub[]>(this.configUrl + 'Club/' + club.clubModelId, club).subscribe(
      (data: IClub[]) => {
       

        if (club.pictureUrl == 'exists') {
          this.updateClubPictureUrl(club, picFile)
        }
        else {
          this.changedClubs.next(data)
        }  
      });
  }
  removeClub(club: IClub) {
    this.http.delete<IClub[]>(this.configUrl + 'Club/' + club.clubModelId).subscribe(
      (data: IClub[]) => this.changedClubs.next(data));
  }
  getClubById(clubID: number) {
    return this.http.get<IClub>(this.configUrl + 'Club/' + clubID);
  }

  // LAP MOD
  private changedLaps = new ReplaySubject<ILap[]>(null);
  broadCastLaps = this.changedLaps.asObservable();
  getLaps(club: IClub) {

    this.http.get<ILap[]>(this.configUrl + 'Lap/ByClub/' + club.clubModelId).subscribe(
      (data: ILap[]) => {
        this.changedLaps.next(data)
      });
  }
  updateLap(lap: ILap) {
    this.http.put<ILap[]>(this.configUrl + 'Lap/' + lap.lapId, lap).subscribe(
      (data: ILap[]) => {
        this.changedLaps.next(data)
        this.getClubs();
      });
  }
  removeLap(lapID: number) {
    this.http.delete<ILap[]>(this.configUrl + 'Lap/' + lapID).subscribe(
      (data: ILap[]) => {
        this.changedLaps.next(data)
        this.getClubs();
      });
  }
  addLap(lap: ILap) {
    this.http.post<ILap[]>(this.configUrl + 'Lap/', lap).subscribe(
      (data: ILap[]) => {
        this.changedLaps.next(data);
        this.getClubs();
      });
  }

  // RFID MOD
  addRFIDReader(rfidReader: IRFIDReader) {
    this.http.post<IRFIDReader>(this.configUrl + 'RFIDReader/', rfidReader).subscribe(
      (data: IRFIDReader) => {
        this.getClubs();
      })
  }
  updateRFIDReader(rfidReader: IRFIDReader) {
    this.http.put<IRFIDReader>(this.configUrl + 'RFIDReader/' + rfidReader.id, rfidReader).subscribe(
      (data: IRFIDReader) => {
        this.getClubs();
      })
  }
  removeRFIDReader(rfidReader: IRFIDReader) {
    this.http.delete<IRFIDReader>(this.configUrl + 'RFIDReader/' + rfidReader.id).subscribe(
      (data: IRFIDReader) => {
        alert(rfidReader.readerName + ' raderades från databasen');
        this.getClubs();
      });
  }
  getRFIDReadersByClub(clubModelId: number) {
    return this.http.get<IRFIDReader[]>(this.configUrl + 'RFIDReader/ByClub/' + clubModelId);  
  }

  // BIKE BRAND MOD
  private changedBikeBrands = new ReplaySubject<IBikeBrand[]>(null);
  broadCastBikeBrands = this.changedBikeBrands.asObservable();
  getBikeBrands() {
    this.http.get<IBikeBrand[]>(this.configUrl + 'BikeBrand/').subscribe(
      (data: IBikeBrand[]) => this.changedBikeBrands.next(data)
    );
  }
  addBikeBrand(bikeBrand: IBikeBrand, picFile: any) {
    this.http.post<IBikeBrand[]>(this.configUrl + 'BikeBrand/', bikeBrand).subscribe(
      (data: IBikeBrand[]) => {
        if (bikeBrand.pictureUrl = 'exists') {
          this.updateBikeBrandPicture(bikeBrand, picFile)
        }
          else this.getBikeBrands(); 
      });
  };
  updateBikeBrandPicture(bikeBrand: IBikeBrand, picFile: any) {
    //Get original filetype
    var fileType = picFile.name.split('.');
    var lastIdx = fileType.lastIndexOf;
    const formData = new FormData();

    formData.append('file', picFile, bikeBrand.bikeBrand + '.' + fileType[fileType.length - 1])

    this.http.post<boolean>(this.configUrl + 'BikeBrand/UploadImage/', formData).subscribe(
      (data: boolean) => {
        if (data)
          this.getBikeBrands();
        else
          alert('could not update picture!')
      })
  };
  updateBikeBrand(bikeBrand: IBikeBrand, picFile:any) {
    this.http.put<IBikeBrand>(this.configUrl + 'BikeBrand/' + bikeBrand.id, bikeBrand).subscribe(
      (data: IBikeBrand) => {
        if (bikeBrand.pictureUrl == 'exists') {
          this.updateBikeBrandPicture(bikeBrand, picFile)
        }
        else
          this.getBikeBrands();
      })
  };
  removeBikeBrand(bikeBrand: IBikeBrand) {
    this.http.delete<IBikeBrand>(this.configUrl + 'BikeBrand/' + bikeBrand.id).subscribe(
      (data: IBikeBrand) => {
        //this.snackbar.open(data.bikeBrand +' har tagits bort från tillverkare')
        alert(data.bikeBrand + ' har tagits bort från tillverkare')
        this.getBikeBrands();
      });
  }

  // SERIE
  private changedSeries = new ReplaySubject<ISerie[]>(null);
  broadCastSeries = this.changedSeries.asObservable();
  getSeries() {
    this.http.get<ISerie[]>(this.configUrl + 'SeriesModels/').subscribe(
      (data: ISerie[]) => this.changedSeries.next(data)
    );
  }
  addSerie(serie: ISerie) {

    this.http.post<ISerie>(this.configUrl + 'SeriesModels/', serie).subscribe(
      (data: ISerie) => {
        //this.changedMembers.next(data)

        this.getSeries();
      });
  }
  updateSerie(serie: ISerie) {
    this.http.put<ISerie>(this.configUrl + 'SeriesModels/' + serie.id, serie).subscribe(
      (data: ISerie) => {
        //this.changedMembers.next(data)
        alert(serie.serieName + ' uppdaterades i databasen');
        this.getSeries();
      });
  }
  removeSerie(serie: ISerie) {
    this.http.delete<ISerie>(this.configUrl + 'SeriesModels/' + serie.id).subscribe(
      (data: ISerie) => {
        //this.changedMembers.next(data)
        alert(serie.serieName + ' raderades från databasen');
        this.getSeries();
      });
  }

//EVENT
  private changedEventTypes = new ReplaySubject<IEventType[]>(null);
  broadCastEventTypes = this.changedEventTypes.asObservable();
  private changedEvents = new ReplaySubject<IEvent[]>(1);
  broadCastEvents = this.changedEvents.asObservable();
  getEventTypes() {
    this.http.get<IEventType[]>(this.configUrl + 'EventType/').subscribe(
      (data: IEventType[]) => {
        this.changedEventTypes.next(data)

      });

  }
  getEvents() {
    this.http.get<IEvent[]>(this.configUrl + 'Event/').subscribe(
      (data: IEvent[]) => {  
        this.changedEvents.next(data)
      });
  }
  getEventsByType(type: number) {
    console.log(type)
    this.http.get<IEvent[]>(this.configUrl + 'Event/ByType/' + type).subscribe(
      (data: IEvent[]) => {
        this.changedEvents.next(data)
      });
  }

  getEventsByClub(clubModelId: number) {
    if (clubModelId)
    this.http.get<IEvent[]>(this.configUrl + 'Event/ByClub/' + clubModelId).subscribe(
      (data: IEvent[]) => {
        this.changedEvents.next(data)
      });
  }
  addEvent(event: IEvent) {
    this.http.post<any>(this.configUrl + 'Event/', event).subscribe(
      (data: any) => {
        this.getEvents();
      }
    )
  }
  getEventLaps(event: IEvent) {
    return this.http.post<ILap[]>(this.configUrl + 'Event/GetEventLaps', event);
  }
  removeEvent(event: IEvent) {
    this.http.delete<IEvent>(this.configUrl + 'Event/' + event.eventId).subscribe(
      (data: IEvent) => {
        //this.changedEvents.next(data)
        alert('Eventet har tagits bort från databasen');
        this.getEvents();
      });
  }

  //PI's
  private changedPis = new ReplaySubject<IPi[]>(null);
  broadCastPis = this.changedPis.asObservable();
  getActivePis() {
    this.http.get<IPi[]>(this.configUrl + 'ActivePis/').subscribe(
      (data: IPi[]) => {
        this.changedPis.next(data)
      });
  }

  //Action EVENTS
  startEvent(event: IEvent) {
    return this.http.post<any>(this.configUrl + 'Event/StartEvent', event).subscribe(
      (data: any) => {
        return data
      }
    )
  }
  pauseEvent(event: IEvent) {
    this.http.post<any>(this.configUrl + 'Event/PauseEvent', event).subscribe(
      (data: any) => {
        return data
      }
    )
  }
  stopEvent(event: IEvent) {
    this.http.post<any>(this.configUrl + 'Event/StopEvent', event).subscribe(
      (data: any) => {
        return data
      }
    )
  }

  //TimeReads
  // GET ALL MEMBERS AND TIMES IN EVENT
  private changedMembersTimesEvent = new ReplaySubject<IMember[]>(null);
  broadCastMembersTimesEvent = this.changedMembersTimesEvent.asObservable();
  getMembersTimesEvent(event: IEvent) {
    this.http.get<IMember[]>(this.configUrl + 'Member/ByEvent/' + event.eventId).subscribe(
      (data: IMember[]) => {
        console.log('Broadcasts data all Member in Event')
        this.changedMembersTimesEvent.next(data)
      });
  }

  //GET ALL INTERMEDIATES FOR ONE RACER IN ONE EVENT
  private changedIntermediateTimes = new ReplaySubject<IIntermediateTimeModel[]>(null);
  broadCastIntermediateTimes = this.changedIntermediateTimes.asObservable();
  getMemberIntermediatesEvent(eventId: number, svemoId: number)  {
    this.http.get<IIntermediateTimeModel[]>
      (this.configUrl + 'IntermediateTime/ByEventSvemo/' + eventId + '/' + svemoId).subscribe(
        (data: IIntermediateTimeModel[]) => {
          this.changedIntermediateTimes.next(data);
          return data;
        });
  }

  //GET ALL LAP TIMES FOR ONE RACER IN ONE EVENT
  private changedLapTimes = new ReplaySubject<IIntermediateTimeModel[]>(null);
  broadCastLapTimes = this.changedLapTimes.asObservable();
  getMemberLapTimesEvent(eventId: number, svemoId: number) {
    this.http.get<IIntermediateTimeModel[]>
      (this.configUrl + 'LapTime/ByEventSvemo/' + eventId + '/' + svemoId).subscribe(
        (data: IIntermediateTimeModel[]) => {
          this.changedLapTimes.next(data);
          return data;
        });
  }

   //GET ALL CONFIRMED TIMEREADS FOR ONE RACER IN ONE EVENT
  private changedConfirmedTimeReads = new ReplaySubject<IConfirmedTimeRead[]>(null);
  broadCastConfirmedTimeReads = this.changedConfirmedTimeReads.asObservable();
  getMemberConfirmedTimeReadsEvent(eventId: number, svemoId: number) {
    this.http.get<IConfirmedTimeRead[]>
      (this.configUrl + 'ConfirmedTimeRead/ByEventSvemo/' + eventId + '/' + svemoId).subscribe(
        (data: IConfirmedTimeRead[]) => {
          this.changedConfirmedTimeReads.next(data);
          return data;
        });
  }



  //GET ALL LAPTIMES FOR MEMBER AND RETURN!!
  getAllMemberLapTimes(member: IMember) {
    return this.http.get<IIntermediateTimeModel[]> (this.configUrl + 'LapTime/ByMember/' + member.svemoId)
  }

  //TO DO Update Event
  updateEvent(eventUpdate: IEvent) {
    this.http.put<IEvent>(this.configUrl + 'Event/' + eventUpdate.eventId, event).subscribe(
      (data: IEvent) => {
        //this.changedMembers.next(data)
        alert(eventUpdate.eventName + ' uppdaterades i databasen');
        this.getEvents();
      });
  }

 



  getMemberProfileUrl(svemoId: number) {

  }




  private changedLapSetUps = new BehaviorSubject<ILapSetUp[]>(null);
  broadCastLapSetUps = this.changedLapSetUps.asObservable();

  private changedTimeReads = new ReplaySubject<ITimeRead[]>(null);
  broadCastTimeRead = this.changedTimeReads.asObservable();

  //private changedConfirmedTimeReads = new ReplaySubject<ITimeRead[]>(null);
  //broadCastConfirmedTimeRead = this.changedConfirmedTimeReads.asObservable();

  //private changedRFIDReaders = new BehaviorSubject<IRFIDReader[]>(null);
  //broadCastRFIDReaders = this.changedRFIDReaders.asObservable();

  private changedStartTypes = new ReplaySubject<IStartType[]>(null);
  broadCastStartTypes = this.changedStartTypes.asObservable();
  getStartTypes() {
    
    this.http.get<IStartType[]>(this.configUrl + 'StartType/').subscribe(
      (data: IStartType[]) => {
        this.changedStartTypes.next(data)
      }
      
    );
  }


  addMembersInCompetition(membersInCompetition: IMemberInCompetition[]) {
    this.http.post<any>(this.configUrl + 'MemberInCompetitions/', membersInCompetition).subscribe(
      (data: any) => {
        alert('ParticipantsAddedToDB')
        return data
      }
    )
  }

  getTimeReadsEvent(event: IEvent) {
    this.http.get<ITimeRead[]>(this.configUrl + 'TimeRead/GetTimesEvent/' + event.eventId).subscribe(
      (data: ITimeRead[]) => {
        this.changedTimeReads.next(data)
      });
  }


}
