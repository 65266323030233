
<mat-sidenav-container class="mw-sidenav-container">
  <mat-sidenav #sidenav role="navigation" mode="push" >
    <!--this is a place for us to add side-nav code-->
    <app-sidenav-list (sidenavClose)="sidenav.close()"></app-sidenav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <!--in here all the content must reside. We will add a navigation header as well-->
    <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
    <app-layout>
      <router-outlet></router-outlet>
    </app-layout>
    
  </mat-sidenav-content>

</mat-sidenav-container>




