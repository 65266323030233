import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { IMember } from '../../models/interfaceModels';
import { AuthenticationService } from '../../auth/authentication.service';
import { ActiveuserService } from '../../services/activeuser.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MemberProfileComponent } from '../../pages/dialogs/member-profile/member-profile.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Output() public sidenavToggle = new EventEmitter() ;

  activeuser: IMember;
  public userRole;
  tokenExists;

  profilePic: any;

  constructor(
    public authService: AuthenticationService,
    private activeUserService: ActiveuserService,
    private router: Router,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {

    this.activeUserService.currentUser.subscribe(user => {
      this.activeuser = user
      if (localStorage.getItem('token') != null) {
        var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
        this.userRole = payLoad.role;
        this.tokenExists = true;
        //let objectURL = 'data:image/jpeg;base64,' + user.picture;
        //this.profilePic = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        if (user.picture.length > 0) {
          let objectURL = 'data:image/jpeg;base64,' + user.picture;
          var Url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.profilePic =  Url;
        }
        else
          this.profilePic =  '/assets/img/profile.png'
      }
    })

    if (localStorage.getItem('token') != null) {
      this.tokenExists = true;
      var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
      this.authService.getSvemoID(payLoad.UserId).subscribe(
        (res: any) => {
          this.activeUserService.changeUser(res)
        }
      )
      this.userRole = payLoad.role;
    }
    else {

      this.activeUserService.activeAsGuest();
      this.tokenExists = false
      this.userRole = "";
    }
  }

  onLogout() {
    this.activeUserService.activeAsGuest();
    localStorage.removeItem('token');
    this.userRole = "";
    this.tokenExists = false
    this.router.navigate(['/home']);
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  showImage(userBlob) {
    let objectURL = 'data:image/jpeg;base64,' + userBlob.image;
    return this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  onViewMember() {
    const dialogRef = this.dialog.open(MemberProfileComponent, {
      width: '100%',
      maxHeight: '500px',
      data: { member: this.activeuser, userRole: this.userRole, activeUser: this.activeuser },
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
