import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() { }

    //public configUrl: string = 'http://localhost:65362/api/';
    public configUrl: string = 'https://apinew.wmstime.se/api/';

    // public configUrlSignalR: string = 'http://localhost:65362/signalRClient';
    public configUrlSignalR: string = 'https://apinew.wmstime.se/signalRClient';

}
