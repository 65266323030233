import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { DbService } from '../../../../services/db.service';
import { IBikeBrand } from '../../../../models/interfaceModels';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-mc-info',
  templateUrl: './mc-info.component.html',
  styleUrls: ['./mc-info.component.css']
})
export class McInfoComponent implements OnInit {

  @ViewChild('dt') table: Table;

  public mcBrands: IBikeBrand[];
 

  constructor(private dbService: DbService,
              private sanitizer: DomSanitizer,) { }

  ngOnInit(): void {
    this.dbService.broadCastBikeBrands.subscribe(data => {
      this.mcBrands = data;
    });

    this.dbService.getBikeBrands();
  }

  onRowEditInit() {

  };

  onAddBikeBrand() {
    var newBikeBrand: IBikeBrand = {
      id: 0,
      bikeBrand: "",
      pictureUrl: "",
      picture: null,
      companyUrl:"",
    }
    this.mcBrands.push(newBikeBrand)
    this.table.initRowEdit(newBikeBrand)
  }

  onRowEditSave(bikeBrand : IBikeBrand) {
    // START TO CLEAN UP club Object
    var picFile: any;

    if (bikeBrand.picture) {
      if (bikeBrand.picture.name) {
        picFile = bikeBrand.picture;
        bikeBrand.pictureUrl = 'exists';
        bikeBrand.picture = null;
      }
    }
    else {
      picFile = null;
      bikeBrand.pictureUrl = "/assets/img/profile.png"
    }

    if (bikeBrand.id == 0)
      this.dbService.addBikeBrand(bikeBrand, picFile);
    else
      this.dbService.updateBikeBrand(bikeBrand, picFile)

  };

  onRowEditCancel() {

  };
  onDeleteClubRFID(bikeBrand: IBikeBrand) {
    this.dbService.removeBikeBrand(bikeBrand)
  };

  showImage(image: any) {
    if (image) {
      let objectURL = 'data:image/jpeg;base64,' + image;
      var Url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    }
    else {
      Url = "/assets/img/nologo.png"
    }
    return Url;
  }
}

