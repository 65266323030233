<div fxLayoutAlign="center center" fxLayout="column" style="padding:1rem">
  <div fxLayoutAlign="center center" fxFlex="100">
    <h3 fxLayout="center center" mat-dialog-title>Redigera varv på {{data.club.name}} </h3>
  </div>


  <div fxLayout="center center" fxFlex="100">
    <form [formGroup]="updateLapForm" (ngSubmit)="onAddClick()">




      <div class="row">
        <div class="col-3">
          <div class="row mt-2">
            <h5>Varv info:</h5>
          </div>
          <div class="row mt-2">
            <mat-form-field>
              <input matInput placeholder="Namn:" formControlName="lapName">
              <mat-error *ngIf="updateLapForm.controls['lapName'].hasError('required')">
                Varv namn <strong>måste anges</strong>
              </mat-error>
              <mat-error *ngIf="updateLapForm.controls['lapName'].hasError('minlength')">
                Varv namn <strong>måste</strong> ha minst 4 bokstäver
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row mt-2">
            <mat-form-field>
              <mat-label>Välj typ</mat-label>
              <mat-select formControlName="type">
                <mat-option>None</mat-option>
                <mat-option value="Träning">Träning</mat-option>
                <mat-option value="Tävling">Tävling</mat-option>
              </mat-select>
              <mat-error *ngIf="updateLapForm.controls['type'].hasError('required')">
                Varv typ <strong>måste </strong>anges
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col9">
          <div class="row mt-2 ml-2">
            <h5>Tid-stationer:</h5>
          </div>
          <button class="mr-2" mat-raised-button color="primary" type="button" (click)="addTimePost()">Lägg till</button>

          <div formArrayName="lapSetUps" *ngFor="let lapSetUp of getLapSetUps.controls; let i = index">
            <div [formGroupName]="i">
              <div class="form-group">

                <div class="row mt-2">
                  <mat-form-field class="ml-4">
                    <input matInput placeholder="Index:" type="number" formControlName="index">
                    <!--<mat-error *ngIf="lapSetUps.controls['id'].hasError('required')">
                    Varv typ <strong>måste </strong>anges
                    </mat-error>-->
                  </mat-form-field>
                  <mat-form-field class="ml-4">
                    <mat-label>Välj position:</mat-label>
                    <mat-select formControlName="name">
                      <mat-option>None</mat-option>
                      <mat-option value="Start">Start</mat-option>
                      <mat-option value="Mellantid">Mellantid</mat-option>
                      <mat-option value="Mål">Mål</mat-option>
                    </mat-select>
                    <mat-error *ngIf="updateLapForm.controls['type'].hasError('required')">
                      Varv typ <strong>måste </strong>anges
                    </mat-error>

                  </mat-form-field>
                  <mat-form-field class="ml-4">
                    <mat-label>Välj läsare:</mat-label>
                    <mat-select formControlName="readerId">
                      <mat-option>None</mat-option>
                      <mat-option *ngFor="let reader of RFIDReaders" value="{{reader.readerName}}">
                        {{reader.readerName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="ml-4">
                    <input matInput placeholder="Antenn:" type="number" formControlName="antennaId">
                    <!--<mat-error *ngIf="lapSetUps.controls['id'].hasError('required')">
                    Varv typ <strong>måste </strong>anges
                    </mat-error>-->
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </form>
  </div>

  <div mat-dialog-actions style="background:red">
    <button mat-button (click)="onNoClick()">Ångra</button>
    <button mat-button (click)="onAddClick()">Ok</button>
  </div>

</div>
