import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-db-info',
  templateUrl: './db-info.component.html',
  styleUrls: ['./db-info.component.css']
})
export class DbInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
