
  <p-table #dt [value]="members" 
           [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
           currentPageReportTemplate="Totalt {totalRecords} medlemmar" [rowsPerPageOptions]="[10,25,50]"
           sortMode="multiple"
           [scrollable]="true" scrollHeight="400px"
           [responsive]="true"
           [filterDelay]="0" [globalFilterFields]="['svemoId','firstName','lastName']"
           styleClass="p-datatable-members" 
           >

    <!--CAPTION-->
    <ng-template pTemplate="caption">

      <div fxLayout="row wrap" fxLayoutGap="1rem" >
        <div fxLayoutAlign="flex-start" fxFlex.gt-xs="40" fxFlex.lt-sm="25" >
          <mat-lable class="name-header-sm">
            Medlemmar
          </mat-lable>
        </div>

        <div fxLayoutAlign="end" fxFlex fxLayoutGap="1rem">
          <button class="mw-addBtn-icon"
                  (click)="onAddMember()" fxLayoutAlign="start start">
                  <mat-icon class="mw-addIcon-icon">add</mat-icon>
          </button>
        </div>


        <!--SMALL SCREEN-->
        <div fxLayoutAlign="flex-start" fxLayoutAlign.xs="flex-center" fxFlex fxLayoutGap="5px" fxHide.gt-sm>
          <mat-form-field>
            <mat-label>Välj klubb:</mat-label>
            <mat-select [formControl]="selectedClubs" multiple (selectionChange)="onClubChange($event)">
              <!--<mat-select-trigger>
        {{selectedClubs.value ? selectedClubs.value[0].name : ''}}
        <span *ngIf="selectedClubs.value?.length > 1" class="additional-selection">
          (+{{selectedClubs.value.length - 1}} {{selectedClubs.value?.length === 2 ? 'other' : 'others'}})
        </span>
      </mat-select-trigger>-->
              <mat-option *ngFor="let club of clubs" [value]="club">{{club.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Global sök" (input)="dt.filterGlobal($event.target.value, 'contains')">
          </mat-form-field>

        </div>
      </div>

    </ng-template>

    <!--HEADER-->
    <ng-template pTemplate="header">
      <!--Only on large screen-->
      <tr fxHide fxShow.gt-sm fxFlexLayout="row" style="height:4rem">
        <th fxFlex="15%" >
          <mat-form-field style="width:5rem">
            <input  matInput placeholder="Svemo ID:" (input)="dt.filter($event.target.value, 'svemoId', 'startsWith')">
          </mat-form-field>
        </th>
        <th fxFlex="30%">
          <mat-form-field class="full-width">
            <input matInput placeholder="Sök på förnamn" (input)="dt.filter($event.target.value, 'firstName', 'contains')">
          </mat-form-field>
        </th>
        <th>
          <mat-form-field>
            <mat-label>Välj klubb:</mat-label>
            <mat-select [formControl]="selectedClubs" multiple (selectionChange)="onClubChange($event)">
              <!--<mat-select-trigger>
              {{selectedClubs.value ? selectedClubs.value[0].name : ''}}
              <span *ngIf="selectedClubs.value?.length > 1" class="additional-selection">
              (+{{selectedClubs.value.length - 1}} {{selectedClubs.value?.length === 2 ? 'other' : 'others'}})
              </span>
              </mat-select-trigger>-->
              <mat-option *ngFor="let club of dataRoom.clubs" [value]="club">{{club.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </th>

      </tr>
      <tr fxFlexLayout="row">
        <th fxFlex="15%" pSortableColumn="svemoId">ID <p-sortIcon field="svemoId"></p-sortIcon></th>
        <th fxFlex="30%" pSortableColumn="firstName"> Name <p-sortIcon field="firstName"></p-sortIcon></th>
        <th fxFlex="20%" pSortableColumn="clubModelId"> Klubb <p-sortIcon field="clubModelId"></p-sortIcon></th>
        <th fxFlex="15%" pSortableColumn="bikeBrandModelId"> MC <p-sortIcon field="bikeBrandModelId"></p-sortIcon></th>
        <th fxFlex>  </th>
      </tr>
    </ng-template>

    <!--BODY-->
    <ng-template pTemplate="body" let-member>

      <!--LARGE SCREEN-->
      <tr fxHide.lt-sm fxFlexLayout="row" style="height:3rem">
        <td fxFlex="15%" fxLayoutAlign="start center">
          <!--<span class="p-column-title"> Svemo ID </span>-->
          {{member.svemoId}}
        </td>
        <td fxFlex="30%" fxLayout fxLayoutAlign="start center">
          <!--<img class="img" width="40" height="40" [src]='member.pictureUrl' style="border-radius:50%; margin-right:10px" />-->
          <img class="img" width="50" height="40" [src]='showImage(member.picture)' style="border-radius:20%; margin-right:10px" />
          <span class="image-text">{{member.firstName}} {{member.lastName}}</span>
        </td>
        <td fxFlex="20%" fxLayoutAlign="start center">
          <!--<span class="p-column-title"> Klubb:: </span>-->
          <!--{{showClubName(member.clubModelId)}}-->
          {{dataRoom.showClubName(member.clubModelId)}}


        </td>
        <td fxFlex="15%" fxLayoutAlign="start center">
          <!--<span class="p-column-title"> Motorcykel: </span>-->
          <!--<img class="img" width="40" height="40" [src]='showImageBikeBrand(member)' style="border-radius:20%; margin-right:10px" />-->
          <img class="img" width="40" height="40" [src]='dataRoom.showBikeBrandImage(member.bikeBrandModelId)' style="border-radius:20%; margin-right:10px" />
        </td>
        <td fxFlex fxLayoutAlign="start center" fxLayoutGap="10px">
          <button class="mw-editBtn-icon" (click)="onViewMember(member)">
            <mat-icon>edit</mat-icon>
          </button>
          <button *ngIf="userRole == 'Admin'" class="mw-deleteBtn-icon" (click)="onMemberDelete(member)">
            <mat-icon class="mw-deleteIcon-icon">clear</mat-icon>
          </button>

        </td>
      </tr>

      <!--SMALL SCREEN-->
      <tr fxHide.gt-xs>
        <td fxLayout fxLayoutAlign="center center" fxLayoutGap="1rem" style="background-color:lightgray; height:4rem ">
          <img class="img" width="50" height="50" [src]='dataRoom.showBikeBrandImage(member.bikeBrandModelId)' style="border-radius:10%; margin-right:10px" />
          <!--<img class="img" width="50" height="50" [src]='showImageBikeBrand(member)' style="border-radius:10%; margin-right:10px" />-->
          <span class="name-header-sm"> {{member.firstName}} {{member.lastName}}</span>
          <img class="img" width="60" height="50" [src]='showImage(member.picture)' style="border-radius:10%; margin-right:10px" />
          <!--<button class="mr-2 PrimaryBtn" color="primary" mat-raised-button (click)="onViewMember(member)">
    <i class="pi pi-user"></i><span style="margin-left:10px">Profil</span>
  </button>-->

        </td>
        <td>
          <span class="p-column-title"> Svemo ID: </span>
          {{member.svemoId}}
        </td>
        <td>
          <span class="p-column-title"> Klubb: </span>
          <!--{{dataRoom.showClubName(member.clubModelId)}}-->
        </td>
        <td>
          <span class="p-column-title"> Motorcykel: </span>
          {{dataRoom.showBikeBrand(member.bikeBrandModelId)}}
        </td>
        <td>
          <div fxFlex fxLayoutAlign="center center" fxLayoutGap="2rem">
            <button class="mw-editBtn-icon" (click)="onViewMember(member)">
              <mat-icon>edit</mat-icon>
            </button>
            <button *ngIf="userRole == 'Admin'" class="mw-deleteBtn-icon" (click)="onMemberDelete(member)">
              <mat-icon class="mw-deleteIcon-icon">clear</mat-icon>
            </button>
          </div>
        </td>
      </tr>

    </ng-template>

  </p-table>

