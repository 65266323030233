import { Injectable, EventEmitter } from '@angular/core';

import * as signalR from "@aspnet/signalr";

import { ConfigService } from './config.service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';


import { ActiveuserService } from './activeuser.service';
import { IClient, IPi, IEvent, IRFIDReader, ITimeRead } from '../models/interfaceModels';
import { DbService } from './db.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  constructor(private config: ConfigService, private dbService: DbService, ) { }

  public isConnected = false;
  //public client: IClient = { connectionId: '', signalRInterest : '', userName : ''  }
  public configUrl: string = this.config.configUrlSignalR;


  private hubConnection: signalR.HubConnection;

  private changedClients = new BehaviorSubject<IClient[]>(null);
  broadCastClients = this.changedClients.asObservable();


  // CHANGE IN PI
  private changedPis = new ReplaySubject<IPi[]>(null);
  broadCastPis = this.changedPis.asObservable();
  // Listens for connected readers
  public changedPiConnectionList() {
    this.hubConnection.on('changedPiConnections', (piList: IPi[]) => {
      console.log('New Pis added')
      console.log(piList)
      this.changedPis.next(piList);
    });
  }

  private changedEvents = new ReplaySubject<IEvent[]>(1);
  broadCastEvents = this.changedEvents.asObservable();

  //private changedReaders = new BehaviorSubject<IRFIDReader[]>(null);
  //broadCastRFIDReaders = this.changedReaders.asObservable();

  //private changedConnectionStatus = new BehaviorSubject<boolean>(false);
  //broadCastConnectionStatus = this.changedConnectionStatus.asObservable();

  public startConnection = () => {

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.configUrl)
      .build();
    this.hubConnection
      .start()
      .then(() => {
        this.isConnected = true;
        console.log('Connection started');
      })
      .catch(err => console.log('Error while starting connection: ' + err))
  }


  //Identifiera användare mot servern
  public sendIdentify(client: IClient) {
    this.hubConnection.invoke('IdentifyConnectionClient', client);
  }




  // Asks for connected readers
  public getPiConnectionList() {
    this.hubConnection.invoke('sendPiListToClient', (piList: IPi[]) => {
      this.changedPis.next(piList);
    });
  }

  public startEvent(event: IEvent) {
    this.hubConnection.invoke('StartEvent', event);
  }
  public startReaders(readers: IRFIDReader[]) {
    console.log(readers)
    this.hubConnection.invoke('startReaders', readers);
  }

  public getEventList(): void {
    this.hubConnection.on('updateEventList', () => {
      this.dbService.getEvents();
    })
  }

  //Gets all connected Clients
  public getClientConnectionList() : void {
    this.hubConnection.on('changedClientConnections', (clients: IClient[]) => {
      this.changedClients.next(clients);
    })
  }



  //Listen for updates in timereads
  public changedTimeReads(event: IEvent): void {
    
    this.hubConnection.on('changedTimeReads', (timeReads: any) => {
      console.log(timeReads)
      //if (timeReads.eventId == event.eventId)
        this.dbService.getTimeReadsEvent(event);
    })
  }
 

  public changedConfirmedTimeReads(event: IEvent): void {
   
    this.hubConnection.on('changedConfirmedTimeReads', (timeReads: any) => {
      console.log(timeReads)
      //if (timeReads.eventId == event.eventId)
      //this.dbService.getConfirmedTimeReadsEvent(event);
      this.dbService.getMembersTimesEvent(event);
    })
  }


  // NEW SignalR
  // keeps track of active event
  public activeEvent: IEvent;


  //Broadcasts new event data
  private newEventData = new ReplaySubject<ITimeRead>();
  broadCastnewEventData = this.newEventData.asObservable();
  public listenForEventData(event: IEvent): void {
    this.hubConnection.on('newEventData', (eventData: ITimeRead) => {
      console.log('CHECK waiting event: ' + event.eventId + ' sending event: ' + eventData.eventId )
      if (event.eventId == eventData.eventId) {
        console.log('CHECK waiting event: ' + event.eventId);
        this.newEventData.next(eventData);
      }    
    })
  }
  public stoplistenForEventData(): void {
    this.hubConnection.off('newEventData');
  }
}
