<p-table #dt [value]="series" dataKey="id" editMode="row">
  <ng-template pTemplate="caption">
    <div fxLayout="row wrap" fxLayoutGap="1rem">
      <div fxLayoutAlign="flex-start" fxFlex.gt-xs="40" fxFlex.lt-sm="25">
        <mat-lable class="name-header-sm">
          Serier
        </mat-lable>
      </div>

      <div fxLayoutAlign="end" fxFlex fxLayoutGap="1rem">
        <button class="mr-2 PrimaryBtn" style="height:2.5rem; margin-right:2rem; background-color:darkseagreen" mat-raised-button
                (click)="onAddSerie()"><mat-icon>person_add</mat-icon></button>
      </div>
    </div>

  </ng-template>

  <ng-template pTemplate="header" >
    <tr fxFlexLayout="row" fxLayoutAlign="start center">
      <th fxFlex="20%" pSortableColumn="serieName">Serie <p-sortIcon field="serieName" ></p-sortIcon></th>
      <th fxFlex="20%" pSortableColumn="serieName">Klubb <p-sortIcon field="serieName"></p-sortIcon></th>
      <th fxFlex="40%" pSortableColumn="serieName">  <p-sortIcon field="serieName"></p-sortIcon></th>
      <th fxFlex ></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-serie let-editing="editing" let-ri="rowIndex">
    <tr fxLayout="row" style="height:3rem" [pEditableRow]="serie">
      <td fxFlex="20%" fxLayout fxLayoutAlign="start center">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <mat-form-field>
              <input matInput type="text" [(ngModel)]="serie.serieName">
            </mat-form-field>
          </ng-template>
          <ng-template pTemplate="output">
            {{serie.serieName}}
          </ng-template>
        </p-cellEditor>
      </td>
      <td fxFlex="20%" fxLayout fxLayoutAlign="start center">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <mat-form-field class="ml-2">
              <mat-label>Välj tillverkare:</mat-label>
              <mat-select [(ngModel)]="serie.clubModel">
                <!--formControlName="bikeBrand"-->
                <mat-option>None</mat-option>
                <mat-option *ngFor="let club of clubs" [value]="club">{{club.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </ng-template>
          <ng-template pTemplate="output">
            {{serie.clubModel.name}}
          </ng-template>
        </p-cellEditor>
      </td>
      <td fxFlex="40%" fxLayout fxLayoutAlign="start center">
        <p-cellEditor>
          <ng-template pTemplate="input">
          
          </ng-template>
          <ng-template pTemplate="output">
         
          </ng-template>
        </p-cellEditor>
      </td>


      <td fxFlex fxLayoutAlign="start center">
        <button *ngIf="!editing" class="mw-btn" mat-raised-button pInitEditableRow (click)="onRowEditInit()">
          <mat-icon>edit</mat-icon>
        </button>
        <button *ngIf="editing" class="mw-btn" mat-raised-button pSaveEditableRow (click)="onRowEditSave(serie)">
          <mat-icon class="mw-accept-icon">done</mat-icon>
        </button>
        <button *ngIf="editing" class="mw-btn" mat-raised-button pCancelEditableRow (click)="onRowEditCancel()">
          <mat-icon class="mw-reject-icon">clear</mat-icon>
        </button>
        <button *ngIf="!editing" class="mw-delete-btn" color="warn" mat-raised-button (click)="onDeleteClubRFID(serie)">
          <mat-icon class="mw-delete-icon">person_remove</mat-icon>
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>
