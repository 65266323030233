import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AuthenticationService } from '../auth/authentication.service';
import { IMember, IClub, IBikeBrand } from '../models/interfaceModels';
import { SignalRService } from './signal-r.service';



@Injectable({
  providedIn: 'root'
})
export class ActiveuserService {


  private user: IMember = {
    svemoId: 0,
    firstName: 'Gäst',
    lastName: '',
    clubModel: <IClub>{},
    clubModelId:0,
    bikeBrandModel: <IBikeBrand>{id: 0, bikeBrand: '', pictureUrl: '', companyUrl: '',},
    bikeBrandModelId: 0,
    pictureUrl: '',
    picture: [],
    confirmedTimeReadModel: [],
    intermediateTimeModel: [],
    lapTimeModel: [],
   // eventTimeReads: [],
  }

  //private messageSource = new BehaviorSubject<User>(this.user)
  private messageSource = new BehaviorSubject<IMember>(this.user)
  currentUser = this.messageSource.asObservable();

  constructor(private authService : AuthenticationService, private signalR:SignalRService) { }

  //changeUser(user: User) {
  changeUser(user: IMember) {
    this.authService.getUserProfile(user).subscribe(
      (res: any) => {
        this.user = res;
        this.messageSource.next(this.user)
        if (this.signalR.isConnected) {
          this.signalR.sendIdentify({ connectionId: '', signalRInterest: '', userName: this.user.svemoId })
        }

      },
      err => {
        alert('Could not find user')
        this.user = {
          svemoId: 0,
          firstName: 'Gäst',
          lastName: '',
          clubModel: <IClub>{},
          clubModelId: 0,
          bikeBrandModel: { id: 0, bikeBrand: '', pictureUrl: '', picture: '', companyUrl: '', },
          bikeBrandModelId: 0,
          pictureUrl: '',
          picture: [],
          confirmedTimeReadModel: [],
          intermediateTimeModel: [],
          lapTimeModel: [],
        }
        this.messageSource.next(this.user)
        if (this.signalR.isConnected) {
          this.signalR.sendIdentify({ connectionId: '', signalRInterest: '', userName: this.user.svemoId })
        }
      },
    )
  }

  activeAsGuest() {
    this.user = {
      svemoId: 0,
      firstName: 'Gäst',
      lastName: '',
      clubModel: <IClub>{},
      clubModelId: 0,
      bikeBrandModel: { id: 0, bikeBrand: '', pictureUrl: '', picture: '', companyUrl: '', },
      bikeBrandModelId: 0,
      pictureUrl: '',
      picture: [],
      confirmedTimeReadModel: [],
      intermediateTimeModel: [],
      lapTimeModel: [],
    }
    if (this.signalR.isConnected) {
      this.signalR.sendIdentify({ connectionId: '', signalRInterest: '', userName: this.user.svemoId })
    }
    this.messageSource.next(this.user)
  }
}
