import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { AuthenticationService } from '../authentication.service';
import { ActiveuserService } from '../../services/activeuser.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IMember } from '../../models/interfaceModels';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  activeuser: IMember;
  userRole;

  constructor(private fb: FormBuilder,
    private authService: AuthenticationService,
    private activeUser: ActiveuserService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) { }


  userLogin = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
  })

  hide = true;

  loading = false;
  submitted = false;



  ngOnInit(): void {
    

  }

  onLogin()  {
    this.submitted = true;
    // stop here if form is invalid
    if (this.userLogin.invalid) {
      this.toastr.error('Du måste ange Svemo ID och lösenord')
      this.userLogin.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.authService.login(this.userLogin.value).subscribe(
      (res: any) => {
        localStorage.setItem('token', res.token);
        this.activeUser.changeUser(this.userLogin.value);
        this.toastr.success('Välkommen till WMS tidtagningssystem', 'Lyckad inloggning:')
        this.router.navigateByUrl('/home');
      },
      err => {
        if (err.status == 400)
          this.toastr.error('Fel användarnamn eller lösenord Identifieringen misslyckades')
          //alert('Fel användarnamn eller lösenord Identifieringen misslyckades');
          
        else {
          console.log(err);
          alert(err.message);
        }
        this.loading = false;
      }

    )
  }

}
