import { Component, OnInit, Inject } from '@angular/core';
import { IClub, ILap, IRFIDReader } from '../../../../../../models/interfaceModels';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { DbService } from '../../../../../../services/db.service';

export interface AddLapData {
  editClub: IClub
}

@Component({
  selector: 'app-add-lap',
  templateUrl: './add-lap.component.html',
  styleUrls: ['./add-lap.component.css']
})


export class AddLapComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AddLapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddLapData,
    private fb: FormBuilder,
    private dbService: DbService) { }

  public lap: ILap;
  addLapForm = this.fb.group({
    id: [0],
    lapName: ["", [Validators.required, Validators.minLength(4)]],
    type: ["", [Validators.required]],
    colorCode: [""],
    club: [this.data.editClub.clubModelId],
    lapSetUps: this.fb.array([
      this.addNewLapSetUpFormGroup(),
    ])
  })

  selectedLap: ILap;
  RFIDReaders: IRFIDReader[];

  ngOnInit(): void {
    this.dbService.getRFIDReadersByClub(this.data.editClub.clubModelId).subscribe(
      data => {
        this.RFIDReaders = data;
      })
  }

  addNewLapSetUpFormGroup(): FormGroup {
    return this.fb.group({
      id: [0, []],
      index: [0, [Validators.required]],
      name: ['', [Validators.required]],
      readerId: ['', [Validators.required]],
      antennaId: [0, [Validators.required]],
      lapModelLapId: [0],
    });
  }

  onNoClick(): void {
    //console.log(this.availableReaders)
    this.dialogRef.close();
  };

  onAddClick(): void {
    console.log(this.addLapForm.value)
    if (this.addLapForm) {
      this.lap = {
        lapId: 0,
        lapName: this.addLapForm.value.lapName,
        type: this.addLapForm.value.type,
        colorCode: this.addLapForm.value.colorCode,
        clubModelId: this.data.editClub.clubModelId,
        lapSetUps: this.addLapForm.value.lapSetUps,
      }

      console.log(this.data.editClub)
      //this.dbService.updateClub(this.data.club)
      this.dbService.addLap(this.lap);
      this.dialogRef.close();
    }
    else
      alert('Form is not valid')

  };

  get getLapSetUps() {
    return <FormArray>this.addLapForm.get('lapSetUps');
  };

  addTimePost(): void {
    (<FormArray>this.addLapForm.get('lapSetUps')).push(this.addNewLapSetUpFormGroup());
  }

}
