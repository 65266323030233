<mat-toolbar class="navBarColor">
  <div fxHide.gt-xs>
    <button mat-icon-button (click)="onToggleSidenav()">
      <mat-icon class="navBarColor">menu</mat-icon>
    </button>
  </div>
  <div fxFlex="20" fxLayout fxLayoutAlign="start">
    <div>
      <a fxHide.xs class="navbar-left" routerLink="/home">
        <img class="img" width="50" src="/assets/img/wmslogo.jpg" />
      </a>
    </div>
    <div fxLayout fxLayoutAlign="center center" class="club-text">
      <a routerLink="/home">Wäxjö MS</a>
    </div>
  </div>

  <div fxFlex fxLayout fxLayoutAlign="center">

    <div fxLayout  fxHide.xs fxLayoutAlign="center center">
      <button class="mw-menu-btn" *ngIf="userRole == 'Admin'" mat-button [matMenuTriggerFor]="belowMenu">Admin</button>
      <mat-menu #belowMenu="matMenu" yPosition="below" class="CustomMenu">
        <span class="headerStyle">Administrera DB</span>
        <button mat-menu-item routerLink="/pages/admin/members">Hantera medlemmar </button>
        <button mat-menu-item routerLink="/pages/admin/dbInfo">Lägg till DB info</button>
        <button mat-menu-item routerLink="/pages/admin/events">Hantera Events</button>
        <mat-divider></mat-divider>
        <span class="headerStyle">Administrera träning</span>
        <button mat-menu-item routerLink="/pages/admin/startTraining">Genomför träning</button>
        <mat-divider></mat-divider>
        <span class="headerStyle">Administrera tävling</span>
        <button mat-menu-item routerLink="/pages/admin/prepareCompetition">Förbered tävling</button>
        <button mat-menu-item routerLink="/pages/admin/runCompetition">Genomför tävling</button>
        <mat-divider></mat-divider>
        <span class="headerStyle">Kontrollera status</span>
        <button mat-menu-item routerLink="/pages/admin/clientStatus">Status enheter</button>
      </mat-menu>

      <button class="mw-menu-btn" mat-button [matMenuTriggerFor]="belowMenu2"><mat-icon>timer</mat-icon> Tider</button>
      <mat-menu #belowMenu2="matMenu" yPosition="below" class="CustomMenu">
        <span class="headerStyle">Träning</span>
        <button mat-menu-item class="CustomMenuItem" routerLink="/pages/results/liveTraining">Live!</button>
        <button mat-menu-item  routerLink="/pages/results/oldTraining">Historik</button>
        <mat-divider></mat-divider>
        <span class="headerStyle">Tävling</span>
        <button mat-menu-item  routerLink="/pages/results/liveCompetition">Live!</button>
        <button mat-menu-item  routerLink="/pages/results/oldCompetition">Historik</button>
      </mat-menu>
      <button class="mw-menu-btn" mat-button [matMenuTriggerFor]="belowMenuLinks">Länkar</button>
      <mat-menu #belowMenuLinks="matMenu" yPosition="below" class="CustomMenu">
        <span class="headerStyle">Klubb länkar</span>
        <button mat-menu-item class="CustomMenuItem" onclick="window.open('http://www.waxjoms.se');">Wäxjö MS</button>
        <button mat-menu-item class="CustomMenuItem" onclick="window.open('http://www.waxjoms.se/mc');">Wäxjö MS Enduro</button>
        <button mat-menu-item class="CustomMenuItem" onclick="window.open('http://www.wmstime.se');">Gamla tidtagningen</button>
        <mat-divider></mat-divider>
        <span class="headerStyle">Enduro länkar</span>
        <button mat-menu-item>Svemo</button>
        <button mat-menu-item>Svemo TA</button>
        <span class="headerStyle">Övrigt</span>
        <button mat-menu-item>Item 1</button>
      </mat-menu>
    </div>
    

    <!--<ul fxLayout fxLayoutGap="15px" class="navigation-items" fxHide.xs>


      <li *ngIf="authService.roleMatch(['Admin'])">
        <a routerLink="/auth/register">Admin</a>
      </li>
      <li>
        <a routerLink="/auth/login">Resultat</a>
      </li>
      <li>
        <a routerLink="/auth/login">Länkar</a>
      </li>
    </ul>-->

  </div>
  <div fxFlex="20" fxLayout fxLayoutAlign="end">
    <ul fxLayout fxLayoutGap="15px" class="navigation-items">
      <li *ngIf="!tokenExists" fxHide.xs>
        <a routerLink="/auth/register" fxHide.xs>Register</a>
      </li>
      <li *ngIf="!tokenExists" fxHide.xs>
        <a routerLink="/auth/login">Login</a>
      </li>
      <li class="cursor-pointer" *ngIf="tokenExists">
        <a (click)="onViewMember()" fxLayout fxLayoutAlign="center center">
          <img class="img"
               style="border-radius:50%; margin-right:10px"
               width="40"
               height="40"
               [src]='profilePic'>
          {{activeuser.firstName}}
        </a>
      </li>
      <li fxHide.xs class="cursor-pointer" *ngIf="tokenExists" fxLayout fxLayoutAlign="center center">
        <a (click)="onLogout()"   >Logout</a>
      </li>
    </ul>
  </div>
</mat-toolbar>
