import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { SignalRService } from './services/signal-r.service';
import { IEvent } from './models/interfaceModels';
import { DbService } from './services/db.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'TimeTrackerClient';

  constructor(private primengConfig: PrimeNGConfig,
    private signalR: SignalRService,
    private dbService: DbService) { }

  public events: IEvent[] = [];

  ngOnInit() {
    this.primengConfig.ripple = true;
    //GET AVAILABLE BIKE BRANDS
    this.dbService.getBikeBrands();
    this.dbService.getClubs();
    // Get available events!
    //this.dbService.broadCastEvents.subscribe(data => {
    //    this.events = data;
    //})

    //this.dbService.getEvents();

    // Starta signaR connection to Server
    this.signalR.startConnection();
  }

}
