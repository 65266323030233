<p-table #dt [value]="events" dataKey="eventId"
         [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
         currentPageReportTemplate="Totalt {totalRecords} event i databasen" [rowsPerPageOptions]="[10,25,50]"
         sortMode="multiple"
         [scrollable]="true" scrollHeight="500px"
         [responsive]="true"
         [filterDelay]="0"
         editMode="row">
  <!--[globalFilterFields]="['svemoId','firstName','lastName']"-->

  <ng-template pTemplate="caption">
    <div fxLayout="row wrap" fxLayoutGap="1rem">
      <div fxLayoutAlign="flex-start" fxFlex.gt-xs="40" fxFlex.lt-sm="25">
        <mat-lable class="name-header-sm">
          Events
        </mat-lable>
      </div>

      <div fxLayoutAlign="end" fxFlex fxLayoutGap="1rem">
        <button class="mw-addBtn-icon" (click)="onAddEvent()" fxLayoutAlign="start start"><mat-icon class="mw-addIcon-icon">add</mat-icon></button>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr fxFlexLayout="row" fxHide.lt-sm style="height:3rem">
      
      <th fxFlex="18%" pSortableColumn="eventDate">Datum <p-sortIcon field="eventDate"></p-sortIcon></th>
      <th fxFlex="18%" pSortableColumn="eventName"> Namn <p-sortIcon field="eventName"></p-sortIcon></th>
      <th fxFlex="19%" pSortableColumn="clubModelId"> Klubb <p-sortIcon field="clubModelId"></p-sortIcon></th>
      <th fxFlex="15%" pSortableColumn="eventType.type"> Event <p-sortIcon field="eventType.type"></p-sortIcon></th>
      <th fxFlex="15%" pSortableColumn="eventType.case"> Typ <p-sortIcon field="eventType.case"></p-sortIcon></th>
      <th fxFlex> </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-events let-ri="rowIndex">
    <!--LARGE SCREEN-->
    <tr fxFlexLayout="row" fxHide.lt-sm style="height:3rem">
      <td fxFlex="18%" fxLayout fxLayoutAlign="start center">
        {{events.eventDate}}
      </td>
      <td fxFlex="18%" fxLayout fxLayoutAlign="start center">
        {{events.eventName}}
      </td>
      <td fxFlex="19%" fxLayout fxLayoutAlign="start center">
        <img class="img" width="40" height="40" [src]='dataRoom.showClubPic(events.clubModelId)' style="border-radius:20%; margin-right:10px" />
        {{dataRoom.showClubName(events.clubModelId)}}
      </td>
      <td fxFlex="15%" fxLayout fxLayoutAlign="start center">
        {{events.eventType.type}}
      </td>
      <td fxFlex="15%" fxLayout fxLayoutAlign="start center">
        {{events.eventType.case}}
      </td>
      <td fxFlex fxLayout fxLayoutAlign="end center">
        <div fxFlex fxLayoutAlign="end center">
          <button class="mw-editBtn-icon" (click)="onEditEvent(events)"><mat-icon>edit</mat-icon></button>
          <button class="mw-deleteBtn-icon" (click)="onDeleteEvent(events)"><mat-icon class="mw-deleteIcon-icon">close</mat-icon></button>
        </div>
      </td>
    </tr>
    <!--SMALL SCREEN-->
    <tr fxHide.gt-xs fxFlexLayout="column" style="height:3rem">
      <td style="background-color:gainsboro; padding:0.5rem" fxLayoutAlign="start center">
        <span fxFlex="20" class="p-column-title"> Namn: </span>
        <span fxFlex="35">{{events.eventName}}</span>
        <div fxFlex fxLayoutAlign="end center">
          <button class="mw-editBtn-icon" (click)="onEditEvent(events)"><mat-icon>edit</mat-icon></button>
          <button class="mw-deleteBtn-icon" (click)="onDeleteEvent(events)"><mat-icon class="mw-deleteIcon-icon">close</mat-icon></button>
        </div>
      </td>
      <td fxLayoutAlign="start center">
        <span class="p-column-title"> Datum: </span>
        {{events.eventDate}}
      </td>
      <td fxLayoutAlign="start center">
        <span class="p-column-title"> Klubb: </span>
        {{dataRoom.showClubName(events.clubModelId)}}
      </td>

    </tr>
</ng-template>




</p-table>
