import { Component, OnInit, Inject } from '@angular/core';
import { IEvent, IClub } from '../../../models/interfaceModels';
import { DbService } from '../../../services/db.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddEventComponent } from './dialogs/add-event/add-event.component';
import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';
import { EditEventComponent } from './dialogs/edit-event/edit-event.component';
import { DomSanitizer } from '@angular/platform-browser';
import { DataRoomService } from '../../../services/data-room.service';

@Component({
  selector: 'app-db-events',
  templateUrl: './db-events.component.html',
  styleUrls: ['./db-events.component.css']
})
export class DbEventsComponent implements OnInit {

  constructor(
    private sanitizer: DomSanitizer,
    private dbService: DbService,
    private dialog: MatDialog,
    public dataRoom:DataRoomService,
   ) { }

  public events: IEvent[];
  public clubs: IClub[];



  ngOnInit(): void {

    this.dbService.broadCastEvents.subscribe(
      data => {
        this.events = data
      })
    this.dbService.broadCastClubs.subscribe(
      data => {
        this.dbService.getEvents();
        this.clubs = data
      })  
    this.dbService.getClubs();

  }

  onAddEvent() {
    const dialogRef = this.dialog.open(AddEventComponent, {
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  onEditEvent(events :IEvent) {
    const dialogRef = this.dialog.open(EditEventComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        editEvent: events,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  onDeleteEvent(events: IEvent) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: events.eventName,
        message: 'Är du säker på att du vill ta bort detta event från datbasen?'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        this.dbService.removeEvent(events);
      }
    })
  }
  //displayClubName(Event: IEvent) {
  //  if (this.clubs)
  //    return this.clubs.find(data => data.clubModelId == Event.clubModelId).name;
  //  else
  //    return "Not available";
  //}

  //showClubPic(Event: IEvent) {

  //  if (this.clubs) {
  //    var clubpic = this.clubs.find(data => data.clubModelId == Event.clubModelId).picture;
  //    if (clubpic.length > 0) {
  //      let objectURL = 'data:image/jpeg;base64,' + clubpic;
  //      var Url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //      return Url;
  //    }
  //    else
  //      return '/assets/img/profile.png'
  //  }
  //  else
  //    return '/assets/img/profile.png'
   
  //}

}
