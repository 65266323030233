import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IBikeBrand, IClub } from '../models/interfaceModels';
import { DbService } from './db.service';

@Injectable({
  providedIn: 'root'
})
export class DataRoomService {

  constructor(private dbService: DbService,
              private sanitizer: DomSanitizer) {
      this.dbService.broadCastBikeBrands.subscribe(data => {
      this.bikeBrands = data;
    });
    dbService.broadCastClubs.subscribe(data => {
      this.clubs = data;
    });
  }

  
  public bikeBrands: IBikeBrand[];
  public showBikeBrand(bikeBrandId: number) {
    return this.bikeBrands.find(data => data.id == bikeBrandId).bikeBrand
  }
  public showBikeBrandImage(bikeBrandId: number) {
    if (this.bikeBrands) {
      var bikePic = this.bikeBrands.find(data => data.id == bikeBrandId).picture;
      if (bikePic) {
        let objectURL = 'data:image/jpeg;base64,' + bikePic;
        var Url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        return Url;
      }
      else
        return '/assets/img/profile.png'
    }
    else
      return '/assets/img/profile.png'
  }
  public clubs: IClub[];
  public showClubName(clubId: number) {
    //console.log('Klubb ID: ' + clubId)
    //console.log(this.clubs)
    if (this.clubs)
      return this.clubs.find(data => data.clubModelId == clubId).name
  }
  public showClubPic(clubId: number) {

    if (this.clubs) {
      var clubpic = this.clubs.find(data => data.clubModelId == clubId).picture;
      if (clubpic) {
        let objectURL = 'data:image/jpeg;base64,' + clubpic;
        var Url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        return Url;
      }
      else
        return '/assets/img/profile.png'
    }
    else
      return '/assets/img/profile.png'

  }

}
