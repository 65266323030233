import { Component, OnInit, ViewChild } from '@angular/core';
import { IMember, IClub, IBikeBrand, IEvent } from '../../../../models/interfaceModels';
import { FormControl } from '@angular/forms';
import { AuthenticationService } from '../../../../auth/authentication.service';
import { Table } from 'primeng/table';
import { DbService } from '../../../../services/db.service';
import { ActiveuserService } from '../../../../services/activeuser.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { DataRoomService } from '../../../../services/data-room.service';

@Component({
  selector: 'app-by-member',
  templateUrl: './by-member.component.html',
  styleUrls: ['./by-member.component.css']
})
export class ByMemberComponent implements OnInit {

  public members: IMember[];
  public clubs: IClub[] = [];
  public BikeBrands: IBikeBrand[];
  public events: IEvent[];

  selectedClubs?= new FormControl();

  public activeuser: IMember;
  tokenExists;
  userRole;

  @ViewChild('dt') table: Table;

  constructor(private dbService: DbService,
    public authService: AuthenticationService,
    public dataRoom:DataRoomService,
    private activeUserService: ActiveuserService,
    //private router: Router,
    //public dialog: MatDialog,
    private sanitizer: DomSanitizer,) { }

  ngOnInit(): void {
    this.dbService.broadCastEvents.subscribe(data => {
      this.events = data; 
    })

    //this.dbService.broadCastBikeBrands.subscribe(data => {
    //  this.BikeBrands = data;
    //  this.dbService.getMembers();
    //})
    this.dbService.getMembers();
    //this.dbService.broadCastClubs.subscribe(data => {
    //  this.clubs = data;
    //  this.dbService.getBikeBrands();
      
    //})
    //this.dbService.getClubs();

    // Starts to subscribe to members will update list if changed
    this.dbService.broadCastMembers.subscribe(data => {
      this.members = data;
    })

    this.activeUserService.currentUser.subscribe(user => {
      this.activeuser = user
      if (localStorage.getItem('token') != null) {
        var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
      }
    })

    if (localStorage.getItem('token') != null) {
      this.tokenExists = true;
      var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
      this.authService.getSvemoID(payLoad.UserId).subscribe(
        (res: any) => {
          this.activeUserService.changeUser(res)
        }
      )
      this.userRole = payLoad.role;
    }
    else {

      this.activeUserService.activeAsGuest();
      this.tokenExists = false
      this.userRole = "";
    }
    // Retrieves initial member list, triggers a broadcastMembers!!
   

  }

  onClubChange(event) {
    var filterClubIds = [];
    event.value.forEach(data => filterClubIds.push(data.clubModelId))
    this.table.filter(filterClubIds, 'clubModelId', 'in')
  }

  showImage(memberPic: any) {
    if (memberPic) {
      let objectURL = 'data:image/jpeg;base64,' + memberPic;
      var Url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      return Url;
    }
    else
      return '/assets/img/profile.png'
  }
  showClubName(member: IMember) {
    return this.clubs.find(data => data.clubModelId == member.clubModelId).name
  }
  showEventDate(eventId:number) {
    return this.events.find(data => data.eventId == eventId).eventDate
  }
  showImageBikeBrand(member: IMember) {
    if (this.BikeBrands) {
      var bikePic = this.BikeBrands.find(data => data.id == member.bikeBrandModelId).picture;
      if (bikePic.length > 0) {
        let objectURL = 'data:image/jpeg;base64,' + bikePic;
        var Url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        return Url;
      }
      else
        return '/assets/img/profile.png'
    }
    else
      return '/assets/img/profile.png'
  }

  expandCommand(member: IMember, expanded: any) {
    this.dbService.getAllMemberLapTimes(member).subscribe(data => {
      member.lapTimeModel = data
    })
  }
}
