import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { ActiveuserService } from '../../services/activeuser.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(private fb: FormBuilder,
    private router: Router,
    private authService: AuthenticationService,
    private activeUser: ActiveuserService,
    private toastr: ToastrService,
  ) { }

  loading = false;
  submitted = false;

  userLogin = this.fb.group({
    email: ['', [Validators.email, Validators.required]],
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
  })

  hide = true;

  ngOnInit(): void {
  }
  onSubmit() {
    this.submitted = true;
    
    // stop here if form is invalid
    if (this.userLogin.invalid) {
      this.toastr.error('Du måste ange Svemo ID, E-mail och lösenord')
      return;
    }

    this.loading = true;
    this.authService.register(this.userLogin.value).subscribe(
      (res: any) => {
        if (res.succeeded) {
          this.toastr.success('Välkommen till WMS tidtagningssystem', 'Lyckad registrering:')
          this.authService.login(this.userLogin.value).subscribe(
            (res: any) => {
              localStorage.setItem('token', res.token);


              this.activeUser.changeUser(this.userLogin.value);
              this.userLogin.reset();
              this.router.navigateByUrl('/home');
            },
            err => {
              if (err.status == 400) {
                console.log(err);
                this.toastr.error('Det angivna Svemo ID finns inte med i databasen', 'Registreringen misslyckades')
              }
              else {
                console.log(err);
                this.toastr.error('Det angivna Svemo ID finns inte med i databasen' + err.message, 'Registreringen misslyckades')
              }
              this.loading = false;
            })


          this.router.navigate(['/home']);

        } else {
          res.errors.forEach(element => {
            switch (element.code) {
              case 'DuplicateUserName':
                this.toastr.error('Finns redan en registrering med detta användarnamn', 'Registreringen misslyckades')
                this.loading = false;
                break;
              case 'SvemoDoesNotExist':
                this.toastr.error('Detta Svemo ID finns inte registrerat som medlem', 'Registreringen misslyckades')
                console.log('Detta Svemo ID finns inte registrerat som medlem', ' Registreringen misslyckades');
                this.loading = false;
                break;
              default:
                this.loading = false;
                this.toastr.error('Något gick fel', 'Registreringen misslyckades')
                console.log(element.description)
                break;
            }
          })
        }
      },
      err => {
        this.toastr.error('Något gick fel', 'Registreringen misslyckades')
        console.log(err.message)
        this.loading = false;
      },
    );
  }
}
