import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { DbService } from '../../../../../services/db.service';
import { IClub, IEvent, IEventType, ILap, ILapInEvent } from '../../../../../models/interfaceModels';

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.css']
})
export class AddEventComponent implements OnInit {



  constructor(public dialogRef: MatDialogRef<AddEventComponent>,
    private fb: FormBuilder, private dbService: DbService) { }

  public clubs: IClub[];
  public selectedClub: IClub;

  public eventTypes: IEventType[];
  public selectedEventType: IEventType[];

  public Laps: ILap[];
  public LapsinEvent: ILap[];
  public selectedLap: ILap;
  public selectedEventLap: ILap;

  addEventForm = this.fb.group({
    id: [0],
    eventDate: ['', [Validators.required]],
    eventName: ['', [Validators.required]],
    clubModelId: [, [Validators.required]],
    eventTypeId: [, [Validators.required]],
    hasStarted: [false],
    weatherData: [''],
    temperature: [''],
    laps: [[], Validators.required ]
  })

  ngOnInit(): void {
    this.LapsinEvent = [];
    this.dbService.broadCastClubs.subscribe(data => {
      this.clubs = data;
    });
    this.dbService.broadCastEventTypes.subscribe(data => {
      this.eventTypes = data;
    });

    this.dbService.getClubs();
    this.dbService.getEventTypes();
  };

  lapInEventCreate(laps: ILap[]): ILapInEvent[] {
    var lapsInEvent: ILapInEvent[];
    lapsInEvent = [];
    laps.forEach(lap => {
      var lapInEvent: ILapInEvent = {
        lapId: lap.lapId,
        lapModel: null,
        eventId: 0,
        eventModel: null,
      };
      lapsInEvent.push(lapInEvent);
    })
    return lapsInEvent;
  }


  onClubChange(event) {
    this.clubs.forEach(club => {
      if (club.clubModelId == event.value) { this.selectedClub = club; }
    })
    this.LapsinEvent = [];
  };


  addEvent() { }

  onClick() {
    console.log(this.LapsinEvent)
  }

  onAddClick() {
    
    if (this.addEventForm.valid) {
      var event: IEvent = {
        eventId: 0,
        eventDate: this.addEventForm.value.eventDate,
        eventName: this.addEventForm.value.eventName,
        club: null,
        clubModelId: +this.addEventForm.value.clubModelId,
        eventType: null,
        eventTypeId: +this.addEventForm.value.eventTypeId,
        hasStarted: 0,
        weatherData: '',
        temperature: '',
        memberInEvent: null,
        memberInCompetition: null,
        timeReadModel: null,
        confirmedTimeReadModel: null,
        intermediateTimeModel: null,
        lapTimeModel: null,
        lapinEvent: this.lapInEventCreate(this.addEventForm.value.laps),
        seriesModel: null,
        classesInEvent: null,
        startType: null,
        startTypeId:0,
      }

      this.dbService.addEvent(event)
      this.dialogRef.close();
    }
    else if (this.addEventForm.valid)
      alert('Du måste välja minst ett varv att följa')
    else
      this.addEventForm.markAllAsTouched();
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
