<p-table #dt [value]="members" dataKey="svemoId"
         [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
         currentPageReportTemplate="Totalt {totalRecords} medlemmar" [rowsPerPageOptions]="[10,25,50]"
         sortMode="multiple"
         [scrollable]="true" scrollHeight="500px"
         [responsive]="true"
         [filterDelay]="0" [globalFilterFields]="['svemoId','firstName','lastName']"
         styleClass="p-datatable-members">


  <!--HEADER-->
  <ng-template pTemplate="header">
    <!--Only on large screen-->
    <tr fxHide fxShow.gt-sm fxFlexLayout="row" style="height:4rem">
      <th fxFlex="5rem"></th>
      <th fxFlex="15%">
        <mat-form-field style="width:5rem">
          <input matInput placeholder="Svemo ID:" (input)="dt.filter($event.target.value, 'svemoId', 'startsWith')">
        </mat-form-field>
      </th>
      <th fxFlex="30%">
        <mat-form-field class="full-width">
          <input matInput placeholder="Sök på förnamn" (input)="dt.filter($event.target.value, 'firstName', 'contains')">
        </mat-form-field>
      </th>
      <th>
        <mat-form-field>
          <mat-label>Välj klubb:</mat-label>
          <mat-select [formControl]="selectedClubs" multiple (selectionChange)="onClubChange($event)">
            <mat-option *ngFor="let club of dataRoom.clubs" [value]="club">{{club.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </th>
    </tr>
    <tr fxHide.gt-sm fxLayoutAlign="center center" fxLayoutGap="2rem">
      <div>
        <mat-form-field>
          <mat-label>Välj klubb:</mat-label>
          <mat-select [formControl]="selectedClubs" multiple (selectionChange)="onClubChange($event)">
            <mat-option *ngFor="let club of dataRoom.clubs" [value]="club">{{club.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="full-width">
          <input matInput placeholder="Sök på förnamn" (input)="dt.filter($event.target.value, 'firstName', 'contains')">
        </mat-form-field>
      </div>
    </tr>
    <tr fxFlexLayout="row">
      <th fxFlex="5rem"></th>
      <th fxFlex="15%" pSortableColumn="svemoId">ID <p-sortIcon field="svemoId"></p-sortIcon></th>
      <th fxFlex="30%" pSortableColumn="firstName"> Name <p-sortIcon field="firstName"></p-sortIcon></th>
      <th fxFlex="20%" > Klubb </th>
      <th fxFlex="15%" > MC </th>
      <th fxFlex>  </th>
    </tr>
  </ng-template>

  <!--BODY-->
  <ng-template pTemplate="body" let-member let-ri="rowIndex" let-expanded="expanded">

    <!--LARGE SCREEN-->
    <tr fxHide.lt-sm fxFlexLayout="row" style="height:3rem">
      <td fxFlex="5rem" fxLayout fxLayoutAlign="start center">
        <button type="button" style="margin:0px"
                pButton pRipple [pRowToggler]="member"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                (click)="expandCommand(member, expanded)">
        </button>
      </td>
      <td fxFlex="15%" fxLayoutAlign="start center">
        <!--<span class="p-column-title"> Svemo ID </span>-->
        {{member.svemoId}}
      </td>
      <td fxFlex="30%" fxLayout fxLayoutAlign="start center">
        <!--<img class="img" width="40" height="40" [src]='member.pictureUrl' style="border-radius:50%; margin-right:10px" />-->
        <img class="img" width="50" height="40" [src]='showImage(member.picture)' style="border-radius:20%; margin-right:10px" />
        <span class="image-text">{{member.firstName}} {{member.lastName}}</span>
      </td>
      <td fxFlex="20%" fxLayoutAlign="start center">
        <!--<span class="p-column-title"> Klubb:: </span>-->
        {{dataRoom.showClubName(member.clubModelId)}}

      </td>
      <td fxFlex="15%" fxLayoutAlign="start center">
        <!--<span class="p-column-title"> Motorcykel: </span>-->
        <img class="img" width="40" height="40" [src]='dataRoom.showBikeBrandImage(member.bikeBrandModelId)' style="border-radius:20%; margin-right:10px" />

      </td>

    </tr>

    <!--SMALL SCREEN-->

    <tr fxHide.gt-xs>
      <td fxLayout fxLayoutAlign="center center" fxLayoutGap="1rem" style="background-color:lightgray; height:4rem ">
        <button type="button" style="margin:0px"
                pButton pRipple [pRowToggler]="member"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                (click)="expandCommand(member, expanded)">
        </button>
        <img class="img" width="50" height="50"  [src]='dataRoom.showBikeBrandImage(member.bikeBrandModelId)' style="border-radius:10%; margin-right:10px" />
        <span class="name-header-sm" style="text-align:center"> {{member.firstName}} {{member.lastName}}</span>
        <img class="img" width="60" height="50" [src]='showImage(member.picture)' style="border-radius:10%; margin-right:10px" />
        <!--<button class="mr-2 PrimaryBtn" color="primary" mat-raised-button (click)="onViewMember(member)">
    <i class="pi pi-user"></i><span style="margin-left:10px">Profil</span>
  </button>-->

      </td>
      <td>
        <span class="p-column-title"> Svemo ID: </span>
        {{member.svemoId}}
      </td>
      <td>
        <span class="p-column-title"> Klubb: </span>
        {{dataRoom.showClubName(member.clubModelId)}}
      </td>

    </tr>

  </ng-template>

  <ng-template pTemplate="rowexpansion" let-member>
    <div fxLayout="row-wrap" fxLayout.xs="column" fxLayoutAlign="space-between start">
      <mat-card fxFlex="40" fxFlex.xs="100" class="MatCard-mw">
        <mat-title><span style="font-weight:500">Filtrera:</span></mat-title>
        <mat-content>
          <div>
            Kommer inom kort!
            <br/>
            <img class="img" width="100%" src="/assets/img/under-construction.png" />
          </div>
          
        </mat-content>
      </mat-card>
      <mat-card fxFlex="60" fxFlex.xs="100" class="MatCard-mw">
        <mat-title><span style="font-weight:500">Tider</span></mat-title>
        <p-table #dtexpand [value]="member.lapTimeModel">
          <ng-template pTemplate="header">
            <th style="background-color:lightgrey" fxFlex="8rem" fxLayoutAlign="center center" pSortableColumn="lapTime.eventId">Datum<p-sortIcon field="lapTime.eventId"></p-sortIcon></th>
            <!--<th fxFlex="5rem" fxLayoutAlign="center center">Event</th>-->
            <th style="background-color:lightgrey" fxFlex fxLayoutAlign="center center" pSortableColumn="lapModel.lapName">Varv<p-sortIcon field="lapModel.lapName"></p-sortIcon></th>
            <th style="background-color:lightgrey" fxFlex="5rem" fxLayoutAlign="center center" pSortableColumn="lapTime">Tid<p-sortIcon field="lapTime"></p-sortIcon></th>
          </ng-template>
          <ng-template pTemplate="body" let-lapTime>
            <tr>
              <td fxFlex="8rem"><span>{{showEventDate(lapTime.eventId)}}</span></td>
              <!--<td fxFlex="5rem"><span fxLayoutAlign="center center">{{lapTime.eventId}}</span></td>-->
              <td fxFlex><span fxLayoutAlign="center center">{{lapTime.lapModel.lapName}}</span></td>
              <td fxFlex="5rem"><span fxLayoutAlign="center center">{{lapTime.lapTime.split(["."]).slice(0,1)}}</span></td>
            </tr>
          </ng-template>
        </p-table>
      </mat-card>


    </div>
  </ng-template>
</p-table>
