<mat-tab-group style="background-color:whitesmoke">
    <mat-tab label="Klubb" >
      <app-club-info ></app-club-info>
    </mat-tab>
    <mat-tab label="MC">
      <app-mc-info></app-mc-info>
    </mat-tab>
    <mat-tab label="Serier">
      <app-series-info></app-series-info>
    </mat-tab>
</mat-tab-group>
