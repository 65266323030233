<button mat-icon-button class="mw-mat-close-button" [mat-dialog-close]="true">
  <mat-icon class="mw-mat-close-icon">close</mat-icon>
</button>
<h3 mat-dialog-title style="margin-left:30px">Lägg till medlem i databasen: </h3>

<form [formGroup]="addMemberForm">
  <div fxLayout.gt-sm="row-wrap" fxLayout.lt-sm="column" fxLayoutGap="10px" fxLayoutGap.lt-md="5px" fxLayoutAlign="center center"
       class="div_mw" style="padding-top:10px">

    <mat-form-field fxFlex="30" fxFlex.lt-sm="90" appearance="fill">
      <mat-label>Svemo ID:</mat-label>
      <input matInput placeholder="Svemo ID:" formControlName="svemoId" type="number">
      <mat-error *ngIf="addMemberForm.controls['svemoId'].hasError('required')">
        Svemo ID <strong>måste </strong>anges
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="30" fxFlex.lt-sm="90" appearance="fill">
      <mat-label>Förnamn:</mat-label>
      <input matInput placeholder="Förnamn:" formControlName="firstName">
      <mat-error *ngIf="addMemberForm.controls['firstName'].hasError('required')">
        Förnamn måste <strong>måste </strong>anges
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="30" fxFlex.lt-sm="90" appearance="fill" class="ml-2">
      <mat-label>Efternamn:</mat-label>
      <input matInput formControlName="lastName">
      <mat-error *ngIf="addMemberForm.controls['lastName'].hasError('required')">
        Efternamn måste <strong>måste </strong>anges
      </mat-error>
    </mat-form-field>
  </div>
  <div fxLayout.gt-sm="row-wrap" fxLayout.lt-sm="column" fxLayoutGap="10px" fxLayoutGap.lt-md="5px" fxLayoutAlign="center center"
       class="div_mw">

    <mat-form-field fxFlex="30" fxFlex.lt-sm="90" appearance="fill" class="ml-2">
      <mat-label>Välj tillverkare:</mat-label>
      <mat-select formControlName="bikeBrandModelId">
        <!--formControlName="bikeBrand"-->
        <mat-option>None</mat-option>
        <mat-option *ngFor="let bikeBrand of bikeBrands" value="{{bikeBrand.id}}">{{bikeBrand.bikeBrand}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="30" fxFlex.lt-sm="90" appearance="fill" class="ml-2">
      <mat-label>Välj klubbtillhörighet:</mat-label>
      <mat-select formControlName="clubModelId">
        <!--formControlName="club"-->
        <mat-option *ngFor="let club of clubs" value="{{club.clubModelId}}">{{club.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--<mat-form-field fxFlex="30" fxFlex.lt-sm="90" appearance="fill" class="ml-2">
    <ngx-mat-file-input [multiple]="multiple" [accept]="accept" placeholder="Välj en fil" [(ngModel)]="pictureUrl">
      <mat-icon style="font-size:25px" ngxMatFileInputIcon>folder_open</mat-icon>
    </ngx-mat-file-input>
    <mat-hint> Välj profilbild </mat-hint>
  </mat-form-field>-->

    <mat-form-field fxFlex="30" fxFlex.lt-sm="90" appearance="fill" class="ml-2">
      <ngx-mat-file-input [multiple]="multiple" [accept]="accept" placeholder="Välj en fil" formControlName="pictureUrl">
        <mat-icon style="font-size:25px" ngxMatFileInputIcon>folder_open</mat-icon>
      </ngx-mat-file-input>
      <mat-hint> Välj profilbild </mat-hint>
    </mat-form-field>

  </div>
  <div fxLayout="row-wrap" fxLayoutGap="10px" fxLayoutGap.lt-md="5px" fxLayoutAlign="center center"
       class="div_mw" style="padding-bottom:10px; margin-bottom:20px" >
    
      <button mat-button color="Accent" mat-raised-button (click)="onNoClick()">Ångra</button>
      <button mat-button color="Accent" mat-raised-button (click)="onAddClick()">Lägg till</button>
  </div>
</form>




