import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Table } from 'primeng/table';
import { IClub, IEvent, IMember } from '../../../../models/interfaceModels';
import { DataRoomService } from '../../../../services/data-room.service';
import { DbService } from '../../../../services/db.service';

@Component({
  selector: 'app-by-training',
  templateUrl: './by-training.component.html',
  styleUrls: ['./by-training.component.css']
})
export class ByTrainingComponent implements OnInit {

  @ViewChild('dt') table: Table;

  constructor(private dbService: DbService,
              public dataRoom: DataRoomService) { }

  events: IEvent[];

  selectedClubs?= new FormControl();

  ngOnInit(): void {
    this.dbService.broadCastEvents.subscribe(data => {
      this.events = data;
    })
    this.dbService.getEventsByType(4);
  }
  onClubChange(event) {
    var filterClubIds = [];
    event.value.forEach(data => filterClubIds.push(data.clubModelId))
    this.table.filter(filterClubIds, 'clubModelId', 'in')
  }

  expandCommand(event: IEvent, expanded: boolean) {
    this.dbService.broadCastMembersTimesEvent.subscribe(data => {
      console.log(data)
      this.events.find(data => data.eventId == event.eventId).memberInEvent = data     
    })
    this.dbService.getMembersTimesEvent(event);
  }
}
