import { Component, OnInit } from '@angular/core';
import { Product } from '../../product';
import { ProductService } from '../../productservice';



@Component({
  selector: 'app-dummy',
  templateUrl: './dummy.component.html',
  styleUrls: ['./dummy.component.css',]

})
export class DummyComponent implements OnInit {

  products: Product[];

  constructor(private productService: ProductService) { }

  ngOnInit() {
    this.productService.getProductsWithOrdersSmall().then(data => {
      this.products = data
      console.log(data)
    });
  }
}
