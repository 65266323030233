import { Component, OnInit } from '@angular/core';
import { IEvent } from '../models/interfaceModels';
import { DbService } from '../services/db.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public events: IEvent[] = [];

  constructor(private dbService: DbService) { }

  ngOnInit(): void {
    //this.dbService.broadCastEvents.subscribe(data => {
    //  this.events = data;
    //})
  }

}
