import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IBikeBrand, IMember } from '../../../models/interfaceModels';
import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { DbService } from '../../../services/db.service';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { DataRoomService } from '../../../services/data-room.service';


@Component({
  selector: 'app-member-profile',
  templateUrl: './member-profile.component.html',
  styleUrls:['./member-profile.component.css']
})
export class MemberProfileComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private dbService: DbService,
    private sanitizer: DomSanitizer,
    public dataRoom:DataRoomService,
    public dialogRef: MatDialogRef<MemberProfileComponent>) {
  }


  // Image files to accept for upload
  public accept = '.jpg, .png'
  public multiple = false;

  public pictureUrl: File;
  private updateUrlMember: IupdateUrlMember;

  public displayPic: any = [];
  public errorMsg: string;

  public bikeBrands: IBikeBrand[];

  ngOnInit(): void {
    this.dbService
      .getProfilePic(this.data.member.svemoId)
      .pipe(catchError(error => {
        console.log(error)
        return of([]);
      })
      ).subscribe(data => {
        this.displayPic = data.picture;
      });
  }

  onUpdatePicUrl() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this.data.member.firstName,
        message: 'Är du säker på att du vill uppdatera din profil bild?'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (!this.pictureUrl)
        this.toastr.error('Du måste välja en fil att ladda upp!')
      if (result === true && this.pictureUrl) {

        this.updateUrlMember= {
          svemoId: this.data.member.svemoId,
          PictureUrl: this.pictureUrl
        }
        this.dbService.updateMemberProfileUrl(this.updateUrlMember)
        this.dialogRef.close();
      }
    });
  }
  showImage() {
      if (this.displayPic) {
        if (this.displayPic.length != 0) {
          let objectURL = 'data:image/jpeg;base64,' + this.displayPic;
          var Url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          return Url;
        }
      }
      else
        return '/assets/img/profile.png'
  }

}

export interface IupdateUrlMember {
  svemoId: number;
  PictureUrl: File;
}

