<mat-nav-list>
  <a routerLink="/member/profile" fxLayout fxLayoutAlign="center center" style="background-color: dimgrey;">
    <h1 style=" margin-right:15px">{{activeuser.firstName}}</h1>
    <img 
         class="img"
         style="border-radius:50%; margin-right:10px"
         width="40"
         height="40"
         [src]='profilePic'>
  </a>
  <a mat-list-item routerLink="/home" (click)="onSidenavClose()">
    <mat-icon>home</mat-icon> <span class="nav-caption">Home</span>
  </a>

  <a *ngIf="userRole == 'Admin'"  mat-list-item [matMenuTriggerFor]="belowMenu"><mat-icon>how_to_reg</mat-icon><span class="nav-caption">Administrera</span></a>
  <mat-menu #belowMenu="matMenu" yPosition="above" xPosition="before" class="CustomMenu">
    <span class="headerStyle">Administrera DB</span>
    <button mat-menu-item routerLink="/pages/admin/members" (click)="onSidenavClose()">Hantera medlemmar</button>
    <button mat-menu-item routerLink="/pages/admin/dbInfo" (click)="onSidenavClose()">Lägg till DB info</button>
    <button mat-menu-item routerLink="/pages/admin/events" (click)="onSidenavClose()">Hantera Events</button>
    <mat-divider></mat-divider>
    <span class="headerStyle">Administrera träning</span>
    <button mat-menu-item routerLink="/pages/admin/startTraining" (click)="onSidenavClose()">Genomför träning</button>
    <mat-divider></mat-divider>
    <span class="headerStyle">Administrera tävling</span>
    <button mat-menu-item (click)="onSidenavClose()" routerLink="/pages/admin/prepareCompetition">Förbered tävling</button>
    <button mat-menu-item (click)="onSidenavClose()" routerLink="/pages/admin/runCompetition">Genomför tävling</button>
    <mat-divider></mat-divider>
    <span class="headerStyle">Kontrollera status</span>
    <button mat-menu-item (click)="onSidenavClose()" routerLink="/pages/admin/clientStatus">Status enheter</button>
  </mat-menu>

  <a mat-list-item [matMenuTriggerFor]="belowMenu2"><mat-icon>timer</mat-icon><span class="nav-caption">Tider</span></a>
  <mat-menu #belowMenu2="matMenu" yPosition="above" xPosition="before" class="CustomMenu">
    <span class="headerStyle">Träning</span>
    <button mat-menu-item class="CustomMenuItem" routerLink="/pages/results/liveTraining" (click)="onSidenavClose()">Live!</button>
    <button mat-menu-item (click)="onSidenavClose()" routerLink="/pages/results/oldTraining">Historik</button>
    <mat-divider></mat-divider>
    <span class="headerStyle">Tävling</span>
    <button mat-menu-item (click)="onSidenavClose()" routerLink="/pages/results/liveCompetition">Live!</button>
    <button mat-menu-item (click)="onSidenavClose()" routerLink="/pages/results/oldCompetition">Historik</button>
  </mat-menu>


  <a mat-list-item routerLink="/owner" (click)="onSidenavClose()">
    <mat-icon>login</mat-icon> <span class="nav-caption">Länkar</span>
  </a>
  <a *ngIf="!tokenExists" mat-list-item routerLink="/auth/register" fxHide.xs (click)="onSidenavClose()">
    <mat-icon>register</mat-icon><span class="nav-caption">Registrera</span>
  </a>
  <a *ngIf="!tokenExists" mat-list-item routerLink="/auth/login" (click)="onSidenavClose()">
    <mat-icon>login</mat-icon><span class="nav-caption">Login</span>
  </a>
  <a *ngIf="tokenExists" mat-list-item (click)="onLogout()">
    <mat-icon>logout</mat-icon><span class="nav-caption">Logout</span>
  </a>
</mat-nav-list>
