import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { DbMembersComponent } from './pages/admin/db-members/db-members.component';
import { DbEventsComponent } from './pages/admin/db-events/db-events.component';
import { DbInfoComponent } from './pages/admin/db-info/db-info.component';
import { DummyComponent } from './pages/dummy/dummy.component';
import { TrainingStartComponent } from './pages/admin/training-start/training-start.component';
import { LiveTrainingComponent } from './pages/results/live-training/live-training.component';
import { OldTrainingComponent } from './pages/results/old-training/old-training.component';
import { LiveCompetitionComponent } from './pages/results/live-competition/live-competition.component';
import { OldCompetitionComponent } from './pages/results/old-competition/old-competition.component';
import { PrepareCompetitionComponent } from './pages/admin/prepare-competition/prepare-competition.component';
import { RunCompetitionComponent } from './pages/admin/run-competition/run-competition.component';
import { ClientStatusComponent } from './pages/admin/client-status/client-status.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'auth',
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent }
    ],
  },
  {
    path: 'pages',
    children: [
      {
        path: 'admin',
        children: [
          { path: 'members', component: DbMembersComponent },
          { path: 'events', component: DbEventsComponent },
          { path: 'dbInfo', component: DbInfoComponent },
          { path: 'startTraining', component: TrainingStartComponent },
          { path: 'prepareCompetition', component: PrepareCompetitionComponent },
          { path: 'runCompetition', component: RunCompetitionComponent },
          { path: 'clientStatus', component: ClientStatusComponent}
        ]
      },
      {
        path: 'results',
        children: [
          { path: 'liveTraining', component: LiveTrainingComponent },
          { path: 'oldTraining', component: OldTrainingComponent },
          { path: 'liveCompetition', component: LiveCompetitionComponent },
          {path:'oldCompetition', component:OldCompetitionComponent}
        ]
      },
      { path: 'dummy', component: DummyComponent }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }
)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
