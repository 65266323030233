<section fxLayout="column" fxFlexAlign="stretch">
  <div fxFlexAlign="center" fxFlex>
    <span fxFlexAlign="center"><p>Välkommen till Wäxjö MS Enduros tidtagningssida</p></span>
  </div>
  <div fxLayout="row-wrap" fxLayout.lt-sm="column" fxLayoutGap="1rem" fxFlexAlign="center">

    <button class="roundLgBtn" routerLink="/pages/results/liveTraining">Live träning</button>
    <button class="roundLgBtn" routerLink="/pages/results/oldTraining">Historik träning</button>
    <button class="roundLgBtn" routerLink="/pages/results/liveCompetition">Live tävling</button>
    <button class="roundLgBtn" routerLink="/pages/results/oldCompetition">Historik tävling</button>

  </div>
  

</section>
