<div fxLayout="row wrap" fxLayoutGap="2rem">
  <div fxLayoutAlign="flex-start" fxFlex>
    <h3 style="margin-left:2rem; font-family:Roboto; font-weight:500"> Lägg till Event: </h3>
  </div>
  <div fxLayoutAlign="end" fxFlex="10" fxLayoutGap="1rem">
    <button mat-icon-button class="mw-mat-close-button" [mat-dialog-close]="true">
      <mat-icon class="mw-mat-close-icon">close</mat-icon>
    </button>
  </div>
</div>
  <form [formGroup]="addEventForm">
    <mat-card-content>
      <div fxLayout="row-wrap" fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutGap="2rem" fxLayoutGap.lt-sm="0rem"   class="div_mw" style="padding-top:10px">

        <mat-form-field fxFlex="45">
          <mat-label>Event datum:</mat-label>
          <input matInput formControlName="eventDate" type="date">
          <mat-error *ngIf="addEventForm.controls['eventDate'].hasError('required')">
            Event datum <strong>måste </strong>anges
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="45">
          <mat-label>Event namn:</mat-label>
          <input matInput formControlName="eventName" type="text">
          <mat-error *ngIf="addEventForm.controls['eventName'].hasError('required')">
            Du <strong>måste </strong>specificera namn på eventet
          </mat-error>
        </mat-form-field>

      </div>

      <div fxLayout="row-wrap" fxLayout.lt-sm="column" fxLayoutGap="2rem" fxLayoutGap.lt-sm="0rem" class="div_mw">
        <mat-form-field appearance="fill" class="ml-3">
          <mat-label>Välj klubb:</mat-label>
          <mat-select formControlName="clubModelId" (selectionChange)="onClubChange($event)">   
            <mat-option *ngFor="let club of clubs" value="{{club.clubModelId}}">{{club.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="ml-3">
          <mat-label>Välj typ av event:</mat-label>
          <mat-select formControlName="eventTypeId">
            <mat-option *ngFor="let eventType of eventTypes" value="{{eventType.id}}">{{eventType.type + ' -' + eventType.case + '-'}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row-wrap" fxLayout.lt-sm="column" fxLayoutGap="2rem" fxLayoutGap.lt-sm="0rem" class="div_mw">
        <mat-form-field *ngIf="selectedClub" appearance="fill" class="ml-3">
          <mat-label>Välj varv::</mat-label>
          <!--<mat-select [(value)]="LapsinEvent" multiple >-->
          <mat-select formControlName="laps" multiple>
            <mat-option *ngFor="let lap of selectedClub.laps" [value]="lap">{{lap.lapName}}</mat-option>
          </mat-select>
          <mat-error *ngIf="addEventForm.controls['laps'].hasError('length')">
            Du <strong>måste </strong>välja minst ett varv
          </mat-error>
        </mat-form-field>

      </div>
    </mat-card-content>
    <mat-card-actions>
      <div fxLayout="row-wrap" fxLayoutGap="10px" fxLayoutGap.lt-md="5px" fxLayoutAlign="center center"
           class="div_mw" style="padding-bottom:10px; margin-bottom:20px">

        <button mat-button color="Accent" mat-raised-button (click)="onNoClick()">Ångra</button>
        <button mat-button color="Accent" mat-raised-button (click)="onAddClick()">Lägg till</button>
      </div>

    </mat-card-actions>
  </form>
